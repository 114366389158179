import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import {  ConfigProvider, Card } from "antd";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import "./HomeLayout.less";
import zh_TW from "antd/es/locale/zh_TW";
import en_US from "antd/es/locale/en_US";
import Images from '../themes/Images';
import _ from "lodash";
import "../mixin/color.less";
import Swal from 'sweetalert2';



const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};


// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

const App = (props) => {
  const size = useWindowSize();
  const [currentKey, setCurrentKey] = useState('tag');
  const [hoverKey, setHoverKey] = useState('');
  const [openGroup, setOpenGroup] = useState([]);
  const [menuVisible, setMenuVisible] = useState(true);
  const [isFullMode, setIsFullMode] = useState(false);
  const [menuList, setMenuList] = useState([{
    name: props.i18n.menu_tag,
    dark_logo: Images.tag_dark,
    light_logo: Images.tag_light,
    key: 'tag',
    moduleKey: 'tag.dashboard.view',
    isShow: false,
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_ability,
    dark_logo: Images.ability_dark,
    light_logo: Images.ability_light,
    key: 'ability',
    moduleKey: 'ability.dashboard.view',
    isShow: false,
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_knowledge,
    dark_logo: Images.knowledge_dark,
    light_logo: Images.knowledge_light,
    key: 'knowledge',
    moduleKey: '',
    isShow: false,
    children: [],
    is_group: true,
    group_key: '',
  }, {
    name: props.i18n.menu_knowledge_character,
    dark_logo: '',
    light_logo: '',
    key: 'knowledge/character',
    moduleKey: 'knowledge1.dashboard.view',
    isShow: false,
    is_group: false,
    group_key: 'knowledge',
  }, {
    name: props.i18n.menu_knowledge_string,
    dark_logo: '',
    light_logo: '',
    key: 'knowledge/string',
    moduleKey: 'knowledge2.dashboard.view',
    isShow: false,
    is_group: false,
    group_key: 'knowledge',
  }, {
    name: props.i18n.menu_knowledge_grammar,
    dark_logo: '',
    light_logo: '',
    key: 'knowledge/grammar',
    moduleKey: 'knowledge3.dashboard.view',
    isShow: false,
    is_group: false,
    group_key: 'knowledge',
  }, {
    name: props.i18n.menu_knowledge_article,
    dark_logo: '',
    light_logo: '',
    key: 'knowledge/article',
    moduleKey: 'knowledge4.dashboard.view',
    isShow: false,
    is_group: false,
    group_key: 'knowledge',
  }, {
    name: props.i18n.menu_knowledge_talk,
    dark_logo: '',
    light_logo: '',
    key: 'knowledge/talk',
    moduleKey: 'knowledge5.dashboard.view',
    isShow: false,
    is_group: false,
    group_key: 'knowledge',
  }, {
    name: props.i18n.menu_member,
    dark_logo: Images.member_dark,
    light_logo: Images.member_light,
    key: 'member',
    moduleKey: '',
    isShow: false,
    is_group: true,
    group_key: '',
  }, {
    name: props.i18n.menu_member_school,
    dark_logo: '',
    light_logo: '',
    key: 'member/school',
    moduleKey: 'school.dashboard.view',
    isShow: false,
    is_group: false,
    group_key: 'member',
  }, {
    name: props.i18n.menu_member_user,
    dark_logo: '',
    light_logo: '',
    key: 'member/user',
    moduleKey: 'member.dashboard.view',
    isShow: false,
    is_group: false,
    group_key: 'member',
  }, {
    name: props.i18n.menu_member_log,
    dark_logo: '',
    light_logo: '',
    key: 'member/log',
    isShow: false,
    is_group: false,
    group_key: 'member',
  }, {
    name: props.i18n.menu_topic,
    dark_logo: Images.topic_dark,
    light_logo: Images.topic_light,
    key: 'topic',
    isShow: false,
    children: [],
    is_group: true,
    group_key: '',
  }, {
    name: props.i18n.menu_topic_group,
    dark_logo: '',
    light_logo: '',
    key: 'topic/group',
    isShow: false,
    moduleKey: 'topicgroup.dashboard.view',
    is_group: false,
    group_key: 'topic',
  }, {
    name: props.i18n.menu_topic_manager,
    dark_logo: '',
    light_logo: '',
    key: 'topic/manager',
    moduleKey: 'topic.dashboard.view',
    isShow: false,
    is_group: false,
    group_key: 'topic',
  }, {
    name: props.i18n.menu_exam,
    dark_logo: Images.exam_dark,
    light_logo: Images.exam_light,
    key: 'exam',
    isShow: false,
    moduleKey: 'exam.dashboard.view',
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_exam_create,
    dark_logo: Images.exam_dark,
    light_logo: Images.exam_light,
    key: 'exam/create',
    isShow: false,
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_exam_update,
    dark_logo: Images.exam_dark,
    light_logo: Images.exam_light,
    key: 'exam/update',
    isShow: false,
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_exam_update,
    dark_logo: Images.exam_dark,
    light_logo: Images.exam_light,
    key: 'exam/topic',
    isShow: false,
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_book,
    dark_logo: Images.book_dark,
    light_logo: Images.book_light,
    key: 'book',
    isShow: false,
    moduleKey: 'book.dashboard.view',
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_game,
    dark_logo: Images.game_dark,
    light_logo: Images.game_light,
    key: 'game',
    isShow: false,
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_course,
    dark_logo: Images.course_dark,
    light_logo: Images.course_light,
    key: 'course',
    moduleKey: 'course.dashboard.view',
    isShow: false,
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_lottery,
    dark_logo: Images.lottery_dark,
    light_logo: Images.lottery_light,
    key: 'lottery',
    moduleKey: 'lottery.dashboard.view',
    isShow: false,
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_statistics,
    dark_logo: Images.statistics_dark,
    light_logo: Images.statistics_light,
    key: 'statistics',
    moduleKey: 'log.dashboard.view',
    isShow: false,
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_review,
    dark_logo: Images.review_dark,
    light_logo: Images.review_light,
    key: 'review',
    isShow: false,
    moduleKey: 'score.dashboard.view',
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_download,
    dark_logo: Images.download_dark,
    light_logo: Images.download_light,
    key: 'download',
    moduleKey: 'download.dashboard.view',
    isShow: false,
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_mail,
    dark_logo: Images.mail_dark,
    light_logo: Images.mail_light,
    key: 'mail',
    isShow: false,
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_news,
    dark_logo: Images.news_dark,
    light_logo: Images.news_light,
    key: 'news',
    isShow: false,
    moduleKey: 'news.dashboard.view',
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_question,
    dark_logo: Images.question_dark,
    light_logo: Images.question_light,
    key: 'question',
    isShow: false,
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_code,
    dark_logo: Images.code_dark,
    light_logo: Images.code_light,
    key: 'code',
    isShow: false,
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_pay,
    dark_logo: Images.pay_dark,
    light_logo: Images.pay_light,
    key: 'pay',
    isShow: false,
    children: [],
    is_group: false,
    group_key: '',
  }, {
    name: props.i18n.menu_pay,
    dark_logo: Images.pay_dark,
    light_logo: Images.pay_light,
    key: 'pay',
    isShow: false,
    children: [],
    is_group: false,
    group_key: '',
  },
  ]);
  let navigate = useNavigate();

  useEffect(() => {
    let tempUrl = window.location.pathname.substring(1);
    menuList.map((item) => {
      if (tempUrl.indexOf(item.key) > -1) {
        setCurrentKey(item.key)
      }
    });
    props.getRoleByPermission();
  }, []);

  useEffect(() => {
    let tempMenuList = JSON.parse(JSON.stringify(menuList));
    props.rolePermission.map((item) => {
      tempMenuList.map((child, index) => {
        if (item.module === child.moduleKey && item.active) {
          tempMenuList[index].isShow = true;
        }
      })
    });
    tempMenuList.map((item) => {
      if (item.isShow && item.group_key !== '') {
        tempMenuList.map((child, index) => {
          if (child.key === item.group_key) {
            tempMenuList[index].isShow = true;
          }
        });
      }
    });

    setMenuList(tempMenuList);
  }, [props.rolePermission])


  useEffect(() => {
    let tempUrl = window.location.pathname.substring(1);
    if (tempUrl.indexOf('book/chapter') > -1) {
      setIsFullMode(true);
    } else {
      setIsFullMode(false);
    }
    menuList.map((item) => {
      if (tempUrl.indexOf(item.key) > -1) {
        setCurrentKey(item.key)
      }
    })
  }, [window.location.pathname]);

  useEffect(() => {
    if (size.height !== undefined && size.width !== undefined) {
      props.SAVE_Screen({ screenWidth: size.width, screenHeight: size.height });
    }
  }, [size]);

  const handleLogout = () => {
    Swal.fire({
      title: '是否要登出',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '確定',
    }).then((result) => {
      if (result.value) {
        props.handleLogout();
      }
    })
  }

  const handleClickMenu = (item) => {
    setCurrentKey(item.key);
    if (!item.is_group) {
      navigate(item.key);
    }
    if (item.is_group) {
      let tempList = JSON.parse(JSON.stringify(openGroup));
      if (openGroup.indexOf(item.key) > -1) {
        setOpenGroup(tempList.filter((child) => child !== item.key));
      } else {
        tempList.push(item.key);
        setOpenGroup(tempList);
      }
    }
  }

  const renderMenuBackground = (item) => {
    if (item.group_key !== '') {
      return (currentKey === item.key || hoverKey === item.key) ? (props.color.currentTheme === 'dark' ? '#378B7B' :'#A5E3CD') :`linear-gradient(to right, ${props.color.menuChildCheckOneColor},  ${props.color.menuChildCheckSecondColor} 60%, ${props.color.menuChildCheckThirdColor}`;
    } else {
      return ((currentKey.indexOf(item.key) > -1 || hoverKey.indexOf(item.key) > -1) || item.group_key !== '') ? `linear-gradient(to right, ${props.color.menuCheckOneColor},  ${props.color.menuCheckSecondColor} 60%, ${props.color.menuCheckThirdColor}` : 'transparent';
    }
  }


  const renderBreadText = () => {
    let current = {};
    menuList.map((item) => {
      if (item.key === currentKey) {
        current = item;
      }
    });
    if (current.group_key !== '') {
      return `${props.i18n.home} / ${props.i18n[`menu_${current.group_key.replace('/','_')}`]} / ${props.i18n[`menu_${currentKey.replace('/','_')}`]}`
    } else {
      return `${props.i18n.home} / ${props.i18n[`menu_${currentKey.replace('/','_')}`]}`
    }
  }

  return (
    <ThemeSwitcherProvider themeMap={themes} defaultTheme={props.theme}>
      <ConfigProvider locale={en_US} componentSize="middle">
        <div 
        id="HomeLayout" 
        style={{ 
          backgroundImage: `url(${props.color.currentTheme === 'light' ? Images.light_bg: Images.dark_bg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          }}>
          <div id="leftWrapper" >
            {menuVisible && <div style={{ paddingLeft: '16px', marginRight: '16px'}}>
              <div style={{ textAlign: 'center', marginTop: '30px'}}>
                <img
                  src={Images.logo}
                  style={{ width: '61px', height: '61px' }}
                  alt="專業英文學測平台"
                />
                <h1
                  style={{
                    fontSize: '24px',
                    color: props.color.logoColor,
                    marginTop: '16px',
                    paddingLeft: '8px',
                  }}>專業英文學測平台</h1>
              </div>
              <div
                style={{
                  padding: '16px 10px',
                  backgroundColor: props.color.leftBackground,
                  borderRadius: '15px',
                  width: '200px',
                  margin: '16px auto',
                  boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
                }}>
                <div style={{ display: 'flex'}}>
                  <img
                    alt="avatar"
                    src={Images.avatar}
                    style={{ width: '61px', height: '61px', borderRadius: '15px' }}
                  />
                  <div style={{ marginLeft: '4px'}}>
                    <div
                      style={{
                        color: props.color.secondColor,
                        fontSize: '16px',
                        marginTop: '4px'
                      }}>{props.userData.member_name}</div>
                    <div
                      style={{
                        color: props.color.leftMenuText,
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                      onClick={handleLogout}
                    >{props.i18n.logout}</div>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: '#CDF3DF',
                    height: '40px',
                    textAlign: 'center',
                    borderRadius: '15px',
                    color: 'black',
                    fontSize: '18px',
                    cursor: 'pointer',
                    width: '100%',
                    lineHeight: '40px',
                    boxShadow: 'inset 0px 3px 6px rgba(0,0,0,0.16)',
                    userSelect: 'none',
                    marginTop: '12px'
                  }}
                  onClick={props.handleChangeI18N}
                >
                  {props.i18nMode === 'en' ? 'EN': '中'}
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '180px',
                    height: '40px',
                    borderRadius: '15px',
                    background: 'linear-gradient(#17A0AA, #005343)',
                    marginTop: '12px',
                    boxShadow: '0px 0px 6px rgba(0,0,0,0.16)'
                  }}
                >
                  <div
                    style={{
                      width: '60px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      borderTopLeftRadius: '15px',
                      borderBottomLeftRadius: '15px',
                      background: props.themeMode === 'light' ? 'linear-gradient(#FFF, #E4EEF8)' : 'transparent',
                      userSelect: 'none',
                    }}
                    onClick={() => props.handleChangeTheme('light')}
                  >
                    <img
                      alt="light"
                      src={props.themeMode === 'light' ? Images.light_selected :Images.light_default}
                      style={{ width: '24px', height: '24px' }} />
                  </div>
                  <div style={{ display: 'flex', zIndex: 1, marginTop: '4px'}}>
                    <div style={{ backgroundColor: '#9F9F9F', width: '1px', height: '32px'}}></div>
                    <div style={{ backgroundColor: '#171717', width: '1px', height: '32px'}}></div>
                  </div>
                  <div
                    style={{
                      width: '60px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      background: props.themeMode === 'dark' ? 'linear-gradient(#002C51, #26695C)': 'transparent',
                      userSelect: 'none',
                    }}
                    onClick={() => props.handleChangeTheme('dark')}
                  >
                    <img
                      alt="light"
                      src={props.themeMode === 'dark' ? Images.dark_selected : Images.dark_default}
                      style={{ width: '19px', height: '19px' }} />
                  </div>
                  <div style={{ display: 'flex', zIndex: 1, marginTop: '4px', marginLeft: '-1px'}}>
                    <div style={{ backgroundColor: '#9F9F9F', width: '1px', height: '32px'}}></div>
                    <div style={{ backgroundColor: '#171717', width: '1px', height: '32px'}}></div>
                  </div>
                  <div
                    style={{
                      width: '60px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      borderTopRightRadius: '15px',
                      borderBottomRightRadius: '15px',
                      background: props.themeMode === 'auto' ? (props.color.currentTheme === 'dark' ? 'linear-gradient(#002C51, #26695C)': 'white'): 'transparent',
                      userSelect: 'none',
                    }}
                    onClick={() => props.handleChangeTheme('auto')}
                  >
                    <img
                      alt="light"
                      src={props.themeMode === 'auto' ? Images.auto_selected:Images.auto_default}
                      style={{ width: '20px', height: '16px' }} />
                  </div>

                </div>
              </div>
              <div 
                style={{
                  borderRadius: '15px',
                  width: '200px',
                  height: `${props.screenHeight - 391}px`,
                  margin: 'auto',
                  padding: '4px 2px 4px 0px',
                  backgroundColor: props.color.leftBackground,
                  boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
                }}
              >
                <div
                  id="menuList"
                  style={{
                    borderRadius: '15px',
                    width: '198px',
                    height: `${props.screenHeight - 399}px`,
                    padding: '8px 0px',
                  }}>
                  {menuList.map((item) => {
                    return ((item.group_key === '' || openGroup.indexOf(item.group_key) > -1 )&& item.isShow) && <div
                      key={item.key}
                      onMouseEnter={() => setHoverKey(item.key)}
                      onMouseLeave={() => setHoverKey('')}
                      onClick={() => handleClickMenu(item)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '33px', 
                        cursor: 'pointer',
                        marginTop: '2px',
                        background: renderMenuBackground(item),
                      }}
                    >
                      {((currentKey === item.key || hoverKey === item.key) || item.group_key !== '') ?
                        <div
                          style={{
                            width: '15px',
                            height: '33px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ width: '5px', height: '33px', backgroundColor: props.color.currentTheme === 'dark' ? '#00FFCE' : '#0BA296', }}></div>
                          {item.group_key !== '' && <div style={{ width: '1px', height: '33px', backgroundColor: '#707070', marginLeft: '2px', marginRight: '2px'}} />}
                          {item.group_key !== '' && <img alt="child" src={Images.menu_child_light} style={{ width: '5px', height: '10px'}} />}
                        </div>
                        : <div style={{ width: '15px', height: '33px' }} />}
                      <div style={{ width: '20px', textAlign: 'center' }}>
                        {item.group_key === '' && <img alt={item.key} src={props.color.currentTheme === 'dark'? item.dark_logo: item.light_logo} />}
                      </div>
                      <div
                        style={{
                          fontSize: '16px',
                          marginLeft: '12px',
                          color: props.color.leftMenuText,
                          width: '128px',
                          userSelect: 'none',
                        }}>{item.name}</div>
                        {item.is_group && <div style={{ width: '30px', textAlign: 'center' }}>
                          <img src={openGroup.indexOf(item.key) > -1 ? (props.color.currentTheme === 'dark' ? Images.menu_ungroup_dark: Images.menu_ungroup_light) :(props.color.currentTheme === 'dark' ? Images.menu_group_dark: Images.menu_group_light)} alt="group"  />
                        </div>}
                      </div>
                  })}
                </div>
              </div>
            </div>}
            <div style={{ display: 'flex', marginTop: '30px'}}>
              <img alt="line" src={Images.line} style={{ width: '1px', height: '710px'}} />
              <img
                alt="bar"
                onClick={() => setMenuVisible(!menuVisible)}
                src={props.color.currentTheme === 'dark' ? Images.bar_dark : Images.bar}
                style={{ minWidth: '30px', height: '106px', marginLeft: '-6px', objectFit: 'none', cursor: 'pointer' }}
              />
            </div>
          </div>
          <div style={{ padding: '0px 16px', width: menuVisible ? 'calc(100vw - 268px)' : 'calc(100vw - 32px)'}}>
            {!isFullMode && <div style={{ marginTop: '30px'}}>
              <div style={{ fontSize: '24px', color: props.color.headerColor}}>
                {props.i18n[`menu_${currentKey.replace('/','_')}`]}
              </div>
              <div style={{ fontSize: '16px', color: props.color.headerColor}}>
                {renderBreadText()}
              </div>
            </div>}
            <div style={{ marginTop: '30px', height: isFullMode ? 'calc(100vh - 60px)' :'calc(100vh - 123px)', overflowY: 'auto', overflowX: 'hidden'}}>
              <Outlet />
            </div>
          </div>
        </div>
      </ConfigProvider>
    </ThemeSwitcherProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', {}),
    userData: _.get(state, 'User.userData', {}),
    rolePermission: _.get(state, 'User.rolePermission', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleLogout(payload, callback, loading) {
      dispatch({ type: 'HANDLE_LOGOUT' });
    },
    CHANGE_Theme(payload, callback, loading) {
      dispatch({ type: 'CHANGE_Theme' });
    },
    SAVE_Screen(payload) {
      dispatch({ type: 'SAVE_Screen', payload });
    },
    handleChangeTheme(payload) {
      dispatch({ type: 'handleChangeTheme', payload });
    },
    getRoleByPermission(payload, callback, loading) {
      dispatch({ type: 'GET_ROLE_BY_PERMISSION', payload, callback, loading });
    },
    handleChangeI18N() {
      dispatch({ type: 'handleChangeI18N' });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
