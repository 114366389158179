import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Form } from 'antd';
import FormInput from "../components/common/FormInput";

import "./Home.less";
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const App = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleLogin = (value) => {
    props.handleLogin();
    
  }

  return <div id="Home">
    <h1 style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '40px' }}>專業英文學測平台</h1>
    
    <Form
      {...layout}
      name="basic"
      initialValues={{
        account: '',
        password: '',
      }}
      style={{ width: '410px', margin: '24px auto 0px auto'}}
      onFinish={value => handleLogin(value)}
    >
      <FormInput
        title="帳號"
        propName='account'
        placeholder="請輸入帳號"
      />
      <FormInput
        title="密碼"
        propName='password'
        type="password"
        placeholder="請輸入密碼"
      />
      <Form.Item>
        <Button loading={isLoading} htmlType="submit">
          登入
        </Button>
      </Form.Item>
    </Form>
  </div>;
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleLogin(payload, callback, loading) {
      dispatch({ type: 'HANDLE_LOGIN' });
    },
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
