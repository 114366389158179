import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Switch, Form, Drawer } from 'antd';
import FormInput from "../components/common/FormInput";
import HtmlEditor from "../components/common/HtmlEditor";
import FormDatePicker from "../components/common/FormDatePicker";
import FormInputSelect from "../components/common/FormInputSelect";
import FormDropdownSelect from "../components/common/FormDropdownSelect";
import Images from "../themes/Images";
import Swal from 'sweetalert2';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from "array-move";
import "./ExamUpdate.less";
import _ from "lodash";
import { showMessage } from "../utils/message";
import moment from "moment";

const abilityList = [
  {
    id: 1,
    name: "A1",
  },
  {
    id: 2,
    name: 'A2',
  },
  {
    id: 3,
    name: 'B1',
  },
  {
    id: 4,
    name: 'B2',
  },
  {
    id: 5,
    name: 'C1',
  },
  {
    id: 6,
    name: 'C2',
  },
]
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const ExamUpdate = (props) => {
  let navigate = useNavigate();
  const createForm = useRef(null);
  const [formValue, setFormValue] = useState({
    abilities: [],
    tags: [],
    exam_desc: '',
    show_answer: false,
    show_score: false,
    is_random: false,
    is_practice: false,
    is_pvqc: false,
    is_big: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(0);
  const [mode, setMode] = useState('create');
  const [items, setItems] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    props.getTopicTypeFormList();
    let url = window.location.pathname.split('/exam/update/')[1];
    const callback = (value) => {
      setItems(value.parts);
    }
    props.getExamById(url, setIsLoading, callback);
    props.getSchoolListByForm({}, setIsLoading);
    props.getCourseFormList();
    let payload = {
      search: null,
      page: 1,
      tags: [],
      abilities: [],
      pageCount: 1000,
      orderKeys: [],
    };
    props.getCourseList(payload);
  }, []);

  useEffect(() => {
    setItems(props.examDetail.parts);
  }, [props.examDetail]);
  
  const renderDrawerTitle = () => {
    if (mode === 'create') {
      return props.i18n.character_create;
    } else if (mode === 'update') {
      return props.i18n.character_update;
    }  else if (mode === 'view') {
      return props.i18n.character_view;
    }
  }

  const renderDrawerView = () => {
    if (mode === 'exam') {
      return drawerVisible && renderExamDrawer();
    } else if (mode === 'createPart' || mode === 'updatePart') {
      return drawerVisible && renderPartDrawer();
      // return drawerVisible && renderFormDrawer();
    }
  }

  const handleUpdateExam = () => {
    setMode('exam');
    let tempTag = [];
    props.getTagFormList(6);
    props.examDetail.tags.map((item) => {
      tempTag.push(item.tag_id);
    });
    let tempAbility = [];
    props.examDetail.abilities.map((item) => {
      tempAbility.push(item.ability_id);
    });
    let tempCourses = [];
    if (_.isArray(props.examDetail.courses)) {
      props.examDetail.courses.map((item) => {
        tempCourses.push(item.id);
      })
    }

    let payload = {
      ...props.examDetail,
      tags: tempTag,
      abilities: tempAbility,
      courses: tempCourses,
      exam_start: props.examDetail.exam_start !== null ? moment(props.examDetail.exam_start): null,
      exam_end: props.examDetail.exam_end !== null ? moment(props.examDetail.exam_end): null,
    };
    setFormValue(payload);
    setDrawerVisible(true);
  }

  const handlePartFinish = (value) => {
    let check = true;
    let errorMessage = '';

    if (!formValue.is_choose && (value.part_items === undefined || value.part_items.length === 0)) {
      check = false;
      errorMessage = "出題題型不得為空";
    }
    if (check) {
      let temp = [];
      if (!formValue.is_choose) {
        formValue.part_items.map((item, index) => {
          temp.push({
            ...item,
            topic_score: parseFloat(value.topic_score),
            topic_count: parseInt(item.topic_count),
            topic_total: parseInt(item.topic_total),
            part_item_order: index + 1,
          });
        });
      }
      let payload = {
        ...formValue,
        topic_score: parseFloat(formValue.topic_score),
        topic_count: parseFloat(formValue.topic_count),
        video_time: parseInt(formValue.video_time),
        exam_id: props.examDetail.exam_id,
        part_id: mode === 'updatePart' ? formValue.part_id: null,
        part_desc: _.isObject(value.part_desc) ? value.part_desc.toHTML() : value.part_desc,
        part_items: temp,
      };
      if (mode === 'updatePart') {
        payload = {
          ...payload,
          part_order: formValue.part_order,
        };
      }

      const callback = () => {
        setDrawerVisible(false);
        setFormValue(false);
        setCurrentData({});
      }

      props.handleUpdatePart(payload, setIsLoading, callback);
    } else {
      showMessage({ type: 'warning', content: errorMessage });
    }

  }

  const renderExamDrawer = () => {
    return <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...formValue,
        }}
        style={{ marginTop: '16px' }}
        onValuesChange={handleFormChange}
        onFinish={handleExamFinish}
      >
      <Row gutter={24} className="scrollList" style={{ maxHeight: `${props.screenHeight - 164}px`, marginRight: '0px', paddingBottom: '8px' }}>
        <Col span={24}>
          <FormInput
            title={props.i18n.exam_title}
            name="exam_title"
            required
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_title}`}
          />
        </Col>
        <Col span={24}>  
          <HtmlEditor
            title={props.i18n.exam_desc}
            i18nMode={props.i18nMode}
            propName="exam_desc"
            value={formValue.exam_desc}
            textColor={props.color.inputTitleColor}
            onEditorStateChange={handleChangeContent}
          />
        </Col>
        <Col span={24}>
          <FormDropdownSelect
            name="tags"
            style={{ width: '100% !important'}}
            mode="multiple"
            type="array"
            options={props.formTagList}
            allowClear={true}
            title={props.i18n.character_tag}
            textColor={props.color.inputTitleColor}
            handleAddItem={handleAddTags}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_tag}`}
          />
        </Col>
        <Col span={24}>
          <FormInputSelect
            name="abilities"
            mode="multiple"
            type="array"
            options={abilityList}
            allowClear={true}
            title={props.i18n.character_ability}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_ability}`}
          />
        </Col>
        <Col span={12}>
          <FormInput
            title={props.i18n.origin_price}
            name="exam_price"
            required
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.origin_price}`}
          />
        </Col>
        <Col span={12}>
          <FormInput
            title={props.i18n.discount_price}
            name="exam_discount"
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.discount_price}`}
          />
        </Col>
        <Col span={12}>
          <FormDatePicker
            title={props.i18n.start_time}
            name="exam_start"
            showTime={{
              defaultValue: moment('00:00:00', 'HH:mm:ss')
            }}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.start_time}`}
          />
        </Col>
        <Col span={12}>
          <FormDatePicker
            title={props.i18n.end_time}
            name="exam_end"
            showTime={{
              defaultValue: moment('23:59:59', 'HH:mm:ss')
            }}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.end_time}`}
          />
        </Col>
        <Col span={12}>
          <FormInput
            name="practice_times"
            type="number"
            title={props.i18n.exam_time}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_time}`}
          />
        </Col>
        <Col span={12}>
          <FormInputSelect
            name="schools"
            mode="multiple"
            type="array"
            options={props.schoolFormList}
            allowClear={true}
            title={props.i18n.lottery_act_school}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.lottery_act_school}`}
          />
        </Col>
        <Col span={24}>
          <FormInputSelect
            name="roles"
            mode="multiple"
            type="array"
            options={[{id: 'M004', name: '學士'}, {id: 'M005', name: '碩士'}]}
            allowClear={true}
            title={props.i18n.exam_role}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_role}`}
          />
        </Col>
        <Col span={24}>
          <FormInputSelect
            name="courses"
            mode="multiple"
            type="array"
            options={props.courseFormList}
            allowClear={true}
            title={props.i18n.exam_course}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_course}`}
          />
        </Col>
        <Col span={12} style={{ display: 'flex', alignItems: 'center'}}>
          <Form.Item name="is_practice" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
          <span
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginLeft: '16px',
              marginBottom: '24px',
            }}
          >{props.i18n.exam_is_exam}</span>
        </Col>
        <Col span={12} style={{ display: 'flex', alignItems: 'center'}}>
          <Form.Item name="is_random" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
          <span
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginLeft: '16px',
              marginBottom: '24px',
            }}
          >{props.i18n.exam_is_fix}</span>
        </Col>
        <Col span={12} style={{ display: 'flex', alignItems: 'center'}}>
          <Form.Item name="show_answer" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
          <span
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginLeft: '16px',
              marginBottom: '24px',
            }}
          >{props.i18n.exam_show_answer}</span>
        </Col>
        <Col span={12} style={{ display: 'flex', alignItems: 'center'}}>
          <Form.Item name="show_score" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
          <span
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginLeft: '16px',
              marginBottom: '24px',
            }}
          >{props.i18n.exam_show_score}</span>
        </Col>
        <Col span={12} style={{ display: 'flex', alignItems: 'center'}}>
          <Form.Item name="show_detail" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
          <span
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginLeft: '16px',
              marginBottom: '24px',
            }}
          >{props.i18n.exam_show_detail}</span>
        </Col>
        <Col span={12} style={{ display: 'flex', alignItems: 'center'}}>
          <Form.Item name="is_pvqc" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
          <span
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginLeft: '16px',
              marginBottom: '24px',
            }}
          >{props.i18n.exam_show_pvqc}</span>
        </Col>
        <Col span={12} style={{ display: 'flex', alignItems: 'center'}}>
          <Form.Item name="is_big" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
          <span
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginLeft: '16px',
              marginBottom: '24px',
            }}
          >{props.i18n.exam_is_big}</span>
        </Col>
      </Row>
      <Form.Item style={{ width: '100%', textAlign: 'center'}}>
        <Button
          loading={isLoading}
          htmlType="submit"
          style={{
            height: '50px',
            backgroundColor: '#4A9E8F',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
            marginRight: '30px'
          }}
        >
          {props.i18n.swal_yes}
        </Button>
        <Button
          loading={isLoading}
          onClick={() => setDrawerVisible(false)}
          style={{
            height: '50px',
            backgroundColor: '#D53838',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
          }}
        >
        {props.i18n.swal_no}
        </Button>
      </Form.Item>
    </Form>

  }

  const renderPartDrawer = () => {
    return <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...formValue,
          is_choose: false,
        }}
        style={{ marginTop: '16px' }}
        onValuesChange={handleFormChange}
        onFinish={handlePartFinish}
      >
      <Row gutter={24} className="scrollList" style={{ maxHeight: `${props.screenHeight - 164}px`, marginRight: '0px', paddingBottom: '8px' }}>
        <Col span={24}>
          <FormInput
            title={props.i18n.exam_page_title}
            name="part_title"
            required
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_page_title}`}
          />
        </Col>
        <Col span={24}>
          <FormInput
            title={props.i18n.exam_page_score}
            name="topic_score"
            required
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_page_score}`}
          />
        </Col>
        <Col span={24}>
          <FormInput
            name="part_time"
            type="number"
            title={props.i18n.exam_time}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_time}`}
          />
        </Col>
        <Col span={24}>
          <FormInput
            name="video_time"
            type="number"
            required
            title={props.i18n.exam_page_video_time}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_page_video_time}`}
          />
        </Col>
        <Col span={24}>
          <FormDropdownSelect
            name="tags"
            style={{ width: '100% !important'}}
            mode="multiple"
            type="array"
            options={props.formTagList}
            allowClear={true}
            title={props.i18n.character_tag}
            textColor={props.color.inputTitleColor}
            handleAddItem={handleAddTags}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_tag}`}
          />
        </Col>
        <Col span={24}>
          <FormInputSelect
            name="abilities"
            mode="multiple"
            type="array"
            options={abilityList}
            allowClear={true}
            title={props.i18n.character_ability}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_ability}`}
          />
        </Col>
        <Col span={24}>  
          <HtmlEditor
            title={props.i18n.exam_desc}
            i18nMode={props.i18nMode}
            propName="part_desc"
            value={formValue.part_desc}
            textColor={props.color.inputTitleColor}
            onEditorStateChange={handleChangeContent}
          />
        </Col>
        <Col span={24} style={{ marginBottom: '30px'}}>
          <div
            style={{
              display: 'flex',
              width: '180px',
              borderRadius: '15px',
              backgroundColor: '#42C1B4',
              border: '1px solid #C1EFD6',
              height: '40px',
              overflow: 'hidden',
            }}>
            <div
              style={{
                width: '89px',
                borderRight: '1px solid #26695C',
                lineHeight: '40px',
                textAlign: 'center',
                color: 'white',
                fontSize: '18px',
                fontWeight: 'bold',
                cursor: 'pointer',
                background: !formValue.is_choose ?'linear-gradient(to bottom, #51C6BA, #004E46)': 'transparent',
              }}
              onClick={() => setFormValue({ ...formValue, is_choose: false })}
            >{props.i18n.exam_auto}</div>
            <div
              style={{
                width: '89px',
                borderLeft: '1px solid #2DA498',
                lineHeight: '40px',
                textAlign: 'center',
                color: 'white',
                fontSize: '18px',
                fontWeight: 'bold',
                  cursor: 'pointer',
                background: formValue.is_choose ?'linear-gradient(to bottom, #51C6BA, #004E46)': 'transparent',
              }}
              onClick={() => setFormValue({ ...formValue, is_choose: true })}
            >{props.i18n.exam_custom}</div>
          </div>
        </Col>
        {formValue.is_choose && <Col span={24}>
          <FormInput
            title={props.i18n.exam_page_topic_count}
            name="topic_count"
            type="number"
            required
            // style={{ width: '10'}}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_page_topic_count}`}
            requiredMessage={`${props.i18n.placeholder}${props.i18n.exam_page_topic_count}`}
          />
        </Col>}
        {!formValue.is_choose && <Col span={24}>
          <div style={{
            width: '100%',
            backgroundColor: props.color.currentTheme === 'dark' ? '#002C51' :'#C2EBE8',
            borderRadius: '20px',
            height: '70px',
            padding: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '16px',
          }}>
            <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%'}}>{props.i18n.exam_page_topic_type}</div>
            <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%'}}>{props.i18n.exam_page_part_topic_store}</div>
            <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%'}}>{props.i18n.exam_page_topic_count}</div>
            <div style={{ fontSize: '18px', color: props.color.textColor, minWidth: '60px', textAlign: 'center'}}>{props.i18n.operation}</div>
          </div>
          <Form.List name="part_items" >
            {(fields, { add, remove }) => (
              <div>
                <div className="scrollList" style={{ maxHeight: `${props.screenHeight - 412}px`, paddingBottom: '8px' }}>
                  {fields.map(({ key, name, ...restField }) => (
                    <div style={{ display: 'flex', width: '100%',padding: '0px 16px 0px 8px'}}>
                      <FormInputSelect
                        {...restField}
                        name={[name, 'part_item_type']}
                        options={[{ id: '-1', name: '綜合題' }, ...props.formTopicTypeList]}
                        required
                        formItemStyle={{
                          marginRight: '16px'               
                        }}
                        type="text"
                        style={{ width: '100% !important', }}
                        textColor={props.color.inputTitleColor}
                        requiredMessage={`${props.i18n.placeholder}${props.i18n.exam_page_topic_type}`}
                        placeholder={`${props.i18n.placeholder}${props.i18n.exam_page_topic_type}`}
                      />
                      <FormInput
                        {...restField}
                        name={[name, 'topic_total']}
                        type="number"
                        formItemStyle={{
                          marginRight: '16px'               
                        }}
                        required
                        style={{ width: '120px'}}
                        textColor={props.color.inputTitleColor}
                        requiredMessage={`${props.i18n.placeholder}${props.i18n.exam_page_part_topic_store}`}
                      />
                      <FormInput
                        {...restField}
                        name={[name, 'topic_count']}
                        type="number"
                        formItemStyle={{
                          marginRight: '16px'               
                        }}
                        required
                        style={{ width: '120px'}}
                        textColor={props.color.inputTitleColor}
                        requiredMessage={`${props.i18n.placeholder}${props.i18n.exam_page_topic_count}`}
                      />
                      <div style={{ minWidth: '60px', height: '50px', display: 'flex', alignItems: 'center'}}>
                        <Button
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '15px',
                            width: '40px',
                            height: '40px',
                            backgroundColor: '#D53838',
                            border: '1px solid #F31616',
                            marginLeft: '8px',
                          }}
                          onClick={() => remove(name)}
                        >
                          <img
                            src={Images.delete}
                            style={{ width: '22px', height: '14px'}}
                            alt={props.i18n.delete}
                          />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add({ topic_count: '0', topic_total: '0'})}
                    block
                    style={{
                      fontSize: '18px',
                      border: '2px dashed #0EA285',
                      height: '50px',
                      lineHeight: '50px',
                      padding: '0px',
                      color: props.color.currentTheme === 'dark'? '#00FFCE': '#26695C',
                      backgroundColor: 'transparent',
                      borderRadius: '15px',
                    }}
                  >
                    + {props.i18n.create_btn}
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Col>}
      </Row>
      <Form.Item style={{ width: '100%', textAlign: 'center'}}>
        <Button
          loading={isLoading}
          htmlType="submit"
          style={{
            height: '50px',
            backgroundColor: '#4A9E8F',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
            marginRight: '30px'
          }}
        >
          {props.i18n.swal_yes}
        </Button>
        <Button
          loading={isLoading}
          onClick={() => setDrawerVisible(false)}
          style={{
            height: '50px',
            backgroundColor: '#D53838',
            width: '128px',
            borderRadius: '20px',
            color: 'white',
            border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
          }}
        >
        {props.i18n.swal_no}
        </Button>
      </Form.Item>
    </Form>

  }
  const handleAddTags = (value, dataCallback) => {
    const { handleCreateTag, saveTagList } = props;
    let payload = {
      tag_name: value,
      tag_type_id: 7,
    }
    const callback = (data) => {
      saveTagList(data);
      dataCallback();

      let temp = [];
      if (formValue.tags === undefined) {
        temp = [data.tag_id];
      } else {
        temp = [...formValue.tags, data.tag_id];
      }
      createForm.current.setFieldsValue({ tags: temp});
      setFormValue({
        ...formValue,
        tags: temp,
      });
    }
    handleCreateTag(payload, setIsLoading, callback);
  }
 
  const onSortEnd = ({ oldIndex, newIndex }) => {
    let tempItems = JSON.parse(JSON.stringify(items));
    let tempData = arrayMoveImmutable(tempItems, oldIndex, newIndex);

    let tempList = [];
    tempData.map((item) => {
      tempList.push(item.part_id);
    });
    let payload = {
      exam_id: props.examDetail.exam_id,
      sort: tempList,
    };
    setItems(tempData);
    setKey(newIndex);
    props.handleSortPart(payload, setIsLoading);
  };

  const handleRemovePartItem = (item) => {
    let payload = {
      part_item_id: item.part_item_id,
      exam_id: props.examDetail.exam_id,
    };
    props.handleDeleteExamPartItem(payload, setIsLoading);
    // let temp = JSON.parse(JSON.stringify(items));
    // temp[itemIndex].part_items = temp[itemIndex].part_items.filter((child, childIndex) => childIndex !== index);
    // setItems(temp);
  }

  const handleAddPart = () => {
    setMode('createPart');
    let tempTag = [];
    props.examDetail.tags.map((item) => {
      tempTag.push(item.tag_id);
    });
    let tempAbility = [];
    props.examDetail.abilities.map((item) => {
      tempAbility.push(item.ability_id);
    });
    props.getTagFormList(6);
    setFormValue({
      tags: tempTag,
      abilities: tempAbility,
    });
    setDrawerVisible(true);
  }

  const handleUpdatePart = (e, index) => {
    e.stopPropagation();

    let tempTag = [];
    let tempPart = [];
    let tempScore = '0';
    items[index].part_items.map((item) => {
      tempScore = JSON.stringify(item.topic_score);
      tempPart.push({
        ...item,
        topic_count: JSON.stringify(item.topic_count),
        topic_total: JSON.stringify(item.topic_total),
      })
    })
    props.getTagFormList(6);
    items[index].tags.map((item) => {
      tempTag.push(item.tag_id);
    });
    let tempAbility = [];
    items[index].abilities.map((item) => {
      tempAbility.push(item.ability_id);
    });
    let payload = {
      ...items[index],
      tags: tempTag,
      abilities: tempAbility,
      part_items: tempPart,
    };
    setFormValue(payload);
    setMode("updatePart");
    setDrawerVisible(true);
  }
  const handleRemovePart = (e, index) => {
    e.stopPropagation();
    Swal.fire({
      title: props.i18n.delete_confirm,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: '#42C1B4',
    }).then((result) => {
      if (result.value) {
        const callback = () => {
          setKey(0);
        }
        props.handleDeleteExamPart({ part_id: items[index].part_id, exam_id: props.examDetail.exam_id }, setIsLoading, callback);
      }
    });
    
  }

  const SortableItem = SortableElement(({value}) => <div style={{
      height: '53px',
      width: '100%',
      background: value.key !== value.index ? 'linear-gradient(to right, rgba(182,229,212, 0.51), rgba(187,255,230,0.51) 60%, rgba(255,255,255,0.51))': 'linear-gradient(to right, rgba(182,229,212), rgba(187,255,230) 60%, rgba(255,255,255))',
      marginBottom: '2px',
      color: '#26695C',
      fontSize: '25px',
      lineHeight: '53px',
      cursor: 'pointer',
      display: 'flex',
      // opacity: value.key === value.index ? 1 : 0.51,
      zIndex: 1,
  }}
    onClick={() => {
      setKey(value.index)
    }}
  >
    <div style={{ display: 'flex', width: '100%'}}>
      <div style={{
        width: '66px',
        textAlign: 'right',
        fontSize: '18px',
        color: '#26695C',
        borderLeft: value.key === value.index ? '8px solid #0BA296': '8px solid transparent',
      }}>
        {(JSON.stringify(value.index+1)).padStart(2, '0')}.
      </div>
      <div style={{
        fontSize: '18px',
        color: '#26695C',
        overflow: 'hidden'
      }}>
        {_.has(value, 'value') ? value.value.part_title: '-'}
      </div>
    </div>
    <div style={{ minWidth: '60px', marginRight: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Button
        style={{
          width: '40px',
          height: '40px',
          backgroundColor: '#42C1B4',
          border: `1px solid #C1EFD6`,
          borderRadius: '15px',
          marginRight: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={e => handleUpdatePart(e, value.index)}
        // onClick={() => navigate(`/exam/update/${item.exam_id}`)}
      >
        <img alt="edit" src={Images.edit} style={{ width: '20px', height: '20px'}} />
      </Button>
      <Button
        style={{
          width: '40px',
          height: '40px',
          backgroundColor: '#D53838',
          border: `1px solid #F31616`,
          borderRadius: '15px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 111,
        }}
        disabled={items.length === 1}
        onClick={e => handleRemovePart(e, value.index)}
      >
        <img alt="delete" src={Images.delete} style={{ width: '19.4px', height: '19.4px'}} />
      </Button>
    </div>
  </div>);

  const SortableList = SortableContainer(({items}) => {
    return (
      <div>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={{ value, index, key }}
          />
        ))}
      </div>
    );
  });
  
  const handleFormChange = (change, all) => {
    if (_.has(change, 'is_pvqc')) {
      setFormValue({
        ...formValue,
        is_big: false,
      });
      createForm.current.setFieldsValue({ is_big: false });
    }
    if (_.has(change, 'is_big')) {
      setFormValue({
        ...formValue,
        is_pvqc: false,
      });
      createForm.current.setFieldsValue({ is_pvqc: false });
    }
    setFormValue({
      ...formValue,
      ...all,
    });
  }

  const handleChangeContent = (value) => {
    // setHtmlText(value.toHTML());
  }

  const handleGetExamPartTopic = (item) => {
    let tempTag = [];
    let tempAbility = [];
    items[key].tags.map((item) => {
      tempTag.push(item.tag_id);
    })
    items[key].abilities.map((item) => {
      tempAbility.push(item.ability_id);
    })
    let payload = {
      id: items[key].part_id,
      type: item.part_item_type,
      tags: tempTag,
      abilities: tempAbility,
    };
    const callback = () => {
      navigate(`/exam/topic/${props.examDetail.exam_id}/${items[key].part_id}/${item.part_item_type}`);
    }
    props.getExamPartTopic(payload, setIsLoading, callback);
  }

  const handleExamFinish = (value) => {
    let payload = {
      ...value,
      schools: formValue.schools !== undefined ? formValue.schools: [],
      exam_start: value.exam_start !== null ? value.exam_start.format('YYYY-MM-DDTHH:mm:ss'): null,
      exam_end: value.exam_end !== null ? value.exam_end.format('YYYY-MM-DDTHH:mm:ss'): null,
      exam_desc: _.isObject(value.exam_desc) ? value.exam_desc.toHTML(): value.exam_desc,
      exam_id: props.examDetail.exam_id,
    }
    const callback = () => {
      setDrawerVisible(false);
      setFormValue({});
    }
    props.handleUpdateExam(payload, setIsLoading, callback);
  }


  return <div style={{ height: `${props.screenHeight - 126}px`, marginRight: '16px', paddingBottom: '8px' }} className="scrollList">
    <div style={{ fontSize: '24px', color: props.color.textColor}}>
      {props.examDetail.exam_title}
    </div>
    <div style={{ fontSize: '18px', color: props.color.textColor, height: '32px', overflow: 'hidden'}} dangerouslySetInnerHTML={{ __html: props.examDetail.exam_desc}}>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', height: '32px' }}>
      <img alt="star" src={Images.star} style={{ width: '26.5px', height: '25px', marginRight: '8px' }} />
      <span style={{ fontSize: '18px', color: props.color.inputTitleColor }}>測驗總分 {props.examDetail.allScore} 分</span>
      <img alt="star" src={Images.exam_page} style={{ width: '27px', height: '27px', marginRight: '8px', marginLeft: '16px' }} />
      <span style={{ fontSize: '18px', color: props.color.inputTitleColor }}>{props.examDetail.is_practice ? '練習試卷' : '測驗試卷'} / {props.examDetail.is_random ? '考題隨機' : '考題固定'} / {props.examDetail.show_answer ? '完成後顯示答案' : '完成後不顯示答案'}</span>
      
      <img alt="star" src={Images.money} style={{ width: '26px', height: '26px', marginRight: '8px', marginLeft: '16px' }} />
      {props.examDetail.exam_discount !== null ? <span style={{ fontSize: '18px', color: props.color.inputTitleColor }}>${props.examDetail.exam_discount} / $<span style={{ textDecoration: 'line-through', color: props.color.inputTitleColor}}>{props.examDetail.exam_price}</span></span> : <span style={{ color: props.color.inputTitleColor}}>${props.examDetail.exam_price}</span>}
    </div>
    <div style={{ marginTop: '16px', display: 'flex', alignItems: 'center'}}>
      <div style={{ display: 'flex', maxWidth: '400px', alignItems: 'center', overflow: 'hidden'}}>
        {
          props.examDetail.tags.map((child, index) => {
            return index < 3 && <div 
              style={{ 
                fontSize: '16px', 
                padding: '0px 12px', 
                backgroundColor: '#FCF1DF', 
                marginRight: '12px',
                color: '#242933',
                height: '40px',
                lineHeight: '40px',
                textAlign: 'center',
                borderRadius: '15px',
              }}
              >
              {child.tag_name}
              </div>
          })
        }
        {props.examDetail.tags.length > 3 && <div style={{
          width: '36px',
          height: '40px',
          lineHeight: '32px',
          borderRadius: '15px',
          backgroundColor: '#FCF1DF', 
          color: '#242933',
          textAlign: 'center',
          marginRight: '16px',
        }}>
          ...
        </div>}
      </div>
      {(props.examDetail.tags.length > 0 && props.examDetail.abilities.length > 0) &&<span style={{ fontSize: '32px', marginRight: '16px'}}>/</span>}
      <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
        {
          props.examDetail.abilities.map((child, index) => {
            return <div 
              style={{ 
                fontSize: '16px', 
                padding: '0px 12px', 
                backgroundColor: '#FCD9BF', 
                marginRight: '12px',
                color: '#242933',
                height: '40px',
                lineHeight: '40px',
                textAlign: 'center',
                borderRadius: '15px',
              }}
              >
              {child.ability_name}
              </div>
          })
        }
      </div>
      <Button
        style={{
          width: '40px',
          height: '40px',
          backgroundColor: '#42C1B4',
          border: `1px solid #C1EFD6`,
          borderRadius: '15px',
          marginLeft: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={handleUpdateExam}
      >
        <img alt="edit" src={Images.edit} style={{ width: '20px', height: '20px'}} />
      </Button>
    </div>
    <div style={{ width: '100%', height: '1px', backgroundColor: '#26695C', marginTop: '8px', marginBottom: '16px' }}></div>
    <Row gutter={24}>
      <Col lg={8} xxl={8}>
        <div style={{ backgroundColor: '#FFF', borderRadius: '15px', padding: '12px 0px'}}>
          <div style={{ padding: '0px 30px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <div
              style={{
                color: props.color.inputTitleColor,
                fontSize: '24px',
                fontWeight: 'bold',
              }}
            >{props.i18n.exam_page_list}</div>
            <div
              style={{
                minWidth: '60px',
                color: props.color.inputTitleColor,
                fontSize: '24px',
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >{props.i18n.operation}</div>
          </div>
         <SortableList
            items={items}
            onSortEnd={onSortEnd}
            distance={1}
          />
          <div
            style={{ 
              height: '50px',
              // width: '100%',
              margin: '30px 12px 12px 12px',
              border: '3px dashed #0EA285',
              borderRadius: '15px',
              fontSize: '18px',
              color: '#26695C',
              textAlign: 'center',
              lineHeight: '44px',
              cursor: 'pointer',
            
            }}
            onClick={handleAddPart}
          >
            + {props.i18n.create_btn}
          </div>
        </div>
      </Col>
      {items.length > 0 && <Col lg={16} xxl={16}>
        <div style={{ width: '100%', minHeight: '400px', borderRadius: '15px', backgroundColor: '#D8EEE2', border: '9px solid #B9E6CE', padding: '12px' }}>
          
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <div style={{ fontSize: '20px', color: '#26695C', fontWeight: 'bold', marginLeft: '24px' }}>{items[key].part_title}</div>
            <div style={{ display: 'flex', color: '#106BD2', fontSize: '18px', justifyContent: 'flex-end'}}>
              {items[key].part_time === 0 ? `時間不限制`: `測驗時間：${items[key].part_time}分`}
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
            <div style={{ display: 'flex', color: '#106BD2', fontSize: '18px'}}>
              <span>共{items[key].part_total_count === 0 ? items[key].topic_count: items[key].part_total_count}題</span> <span style={{ marginLeft: '16px'}}>每題{items[key].topic_score}分</span><span style={{ marginLeft: '16px'}}>總分{items[key].part_total_score}分</span>
            </div>
          </div>
          
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', maxWidth: '400px', alignItems: 'center', overflow: 'hidden'}}>
              {
                items[key].tags.map((child, index) => {
                  return index < 3 && <div 
                    style={{ 
                      fontSize: '14px', 
                      padding: '0px 12px', 
                      backgroundColor: '#FCF1DF', 
                      marginRight: '12px',
                      color: '#242933',
                      height: '30px',
                      lineHeight: '30px',
                      textAlign: 'center',
                      borderRadius: '15px',
                    }}
                    >
                    {child.tag_name}
                    </div>
                })
              }
              { items[key].tags.length > 3 && <div style={{
                width: '36px',
                height: '30px',
                lineHeight: '32px',
                borderRadius: '15px',
                backgroundColor: '#FCF1DF', 
                color: '#242933',
                textAlign: 'center'
              }}>
                ...
              </div>}
            </div>
            {(items[key].tags.length > 0 && items[key].abilities.length > 0) && <span style={{ fontSize: '32px', marginRight: '16px'}}>/</span>}
            <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              {
                 items[key].abilities.map((child, index) => {
                  return <div 
                    style={{ 
                      fontSize: '14px', 
                      padding: '0px 12px', 
                      backgroundColor: '#FCD9BF', 
                      marginRight: '12px',
                      color: '#242933',
                      height: '30px',
                      lineHeight: '30px',
                      textAlign: 'center',
                      borderRadius: '15px',
                    }}
                    >
                    {child.ability_name}
                    </div>
                })
              }
            </div>
          </div>
          <div
            style={{ fontSize: '18px', color: props.color.textColor, marginLeft: '16px' }}
            dangerouslySetInnerHTML={{__html: items[key].part_desc }}
          >
          </div>
          {items[key].is_choose &&
            <Button
              style={{
                width: '128px',
                height: '40px',
                borderRadius: '15px',
                backgroundColor: '#4A9E8F',
                border: '2px solid #C7E3D4',
                color: 'white',
                marginTop: '16px'
              }}
              onClick={() => navigate(`/exam/topic/${props.examDetail.exam_id}/${items[key].part_id}/0`)}
            >檢視題庫</Button>}

          {!items[key].is_choose && <div style={{
            width: '100%',
            backgroundColor: props.color.currentTheme === 'dark' ? '#002C51' :'#C2EBE8',
            borderRadius: '20px',
            height: '70px',
            padding: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '16px',
            marginTop: '16px'
          }}>
            <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%'}}>{props.i18n.exam_page_topic_type}</div>
            <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%', textAlign: 'center'}}>{props.i18n.exam_page_topic_store}</div>
            <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%', textAlign: 'center'}}>{props.i18n.exam_page_topic_count}</div>
            <div style={{ fontSize: '18px', color: props.color.textColor, minWidth: '100px', textAlign: 'center'}}>{props.i18n.operation}</div>
          </div>}
          {items[key].part_items.map((item, index) => {
              return <div style={{ display: 'flex', alignItems: 'center', width: '100%',padding: '0px 16px 0px 8px', marginBottom: '16px'}}>
                <div style={{ fontSize: '18px', color: '#26695C', width: '100%', paddingLeft: '16px'}}>{item.part_item_title}</div>
                <div style={{ fontSize: '18px', color: '#26695C', width: '100%', textAlign: 'center'}}>{item.topic_total}</div>
                <div style={{ fontSize: '18px', color: '#26695C', width: '100%', textAlign: 'center'}}>{item.topic_count}</div>
                <div style={{ minWidth: '100px', height: '50px', display: 'flex', alignItems: 'center'}}>
                  <Button
                    style={{
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#42C1B4',
                      border: `1px solid #C1EFD6`,
                      borderRadius: '15px',
                      marginLeft: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    disabled={item.part_item_title === '綜合題'}
                    onClick={() => handleGetExamPartTopic(item)}
                  >
                    <img alt="edit" src={Images.edit} style={{ width: '20px', height: '20px'}} />
                  </Button>
                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '15px',
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#D53838',
                      border: '1px solid #F31616',
                      marginLeft: '8px',
                    }}
                    onClick={() => handleRemovePartItem(item)}
                  >
                    <img
                      src={Images.delete}
                      style={{ width: '22px', height: '14px'}}
                      alt={props.i18n.delete}
                    />
                  </Button>
                </div>
              </div>
            })
          }
        </div>
      </Col>}
    </Row>
     {<Drawer
      placement="right"
      className={props.color.currentTheme === 'dark' ? 'dark_member' : 'light_member'}
      closable={false}
      maskClosable={mode === 'view' ? true :false}
      onClose={() => setDrawerVisible(false)}
      visible={drawerVisible}
    >
      <div style={{ display: 'flex', alignItems: 'center'}}>
        <img
          src={Images.close}
          alt="close"
          style={{ width: '65px', height: '74px', cursor: 'pointer' }}
          onClick={() => setDrawerVisible(false)}
        />
        <div
          style={{
            fontSize: '24px',
            color: props.color.inputDrawerTitleColor,
            marginLeft: '30px',
          }}>{renderDrawerTitle()}</div>
      </div>
      {renderDrawerView()}
    </Drawer>}
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    examList: _.get(state, 'Exam.examList', []),
    paging: _.get(state, 'Exam.paging', {}),
    examDetail: _.get(state, 'Exam.examDetail', {}),
    formTagList: _.get(state, 'Tag.formTagList', []),
    schoolFormList: _.get(state, 'School.schoolFormList', []),
    courseFormList: _.get(state, 'Course.courseFormList', []),
    courseList: _.get(state, 'Course.courseList', []),
    formTopicTypeList: _.get(state, 'TopicManager.topicTypeFormList', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExamList(payload, loading, callback) {
      dispatch({ type: 'GET_EXAM_LIST', payload, loading, callback});
    },
    getCourseList(payload, loading, callback) {
      dispatch({ type: 'GET_COURSE_LIST', payload, loading, callback});
    },
    getCourseFormList(payload, loading, callback) {
      dispatch({ type: 'GET_COURSE_FORM_LIST', payload, loading, callback});
    },
    handleDeleteExam(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_DELETE_EXAM', payload, loading, callback, getData});
    },
    handleDeleteExamPartItem(payload, loading, callback) {
      dispatch({ type: 'HANDLE_DELETE_PART_ITEM', payload, loading, callback});
    },
    getExamById(payload, loading, callback) {
      dispatch({ type: 'GET_EXAM_BY_ID', payload, loading, callback});
    },
    getTagFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TAG_FORM_LIST', payload, loading, callback});
    },
    handleCreateTag(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_TAG', payload, loading, callback});
    },
    handleUpdateExam(payload, loading, callback) {
      dispatch({ type: 'HANDLE_UPDATE_EXAM', payload, loading, callback});
    },
    handleUpdatePart(payload, loading, callback) {
      dispatch({ type: 'HANDLE_UPDATE_PART', payload, loading, callback});
    },
    getExamPartTopic(payload, loading, callback) {
      dispatch({ type: 'GET_EXAM_PART_TOPIC', payload, loading, callback});
    },
    handleDeleteExamPart(payload, loading, callback) {
      dispatch({ type: 'HANDLE_DELETE_EXAM_PART', payload, loading, callback});
    },
    saveTagList(payload) {
      dispatch({ type: 'SAVE_TAG_LIST', payload});
    },
    getTopicTypeFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TOPIC_TYPE_FORM_LIST', payload, loading, callback});
    },
    handleSortPart(payload, loading, callback) {
      dispatch({ type: 'HANDLE_SORT_PART', payload, loading, callback});
    },
    getSchoolListByForm(payload, loading, callback) {
      dispatch({ type: 'GET_SCHOOL_LIST_BY_FORM', payload, loading, callback});
    },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamUpdate);
