import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Switch, Form } from 'antd';
import { arrayMoveImmutable } from "array-move";
import moment from 'moment';
import Swal from 'sweetalert2';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import _ from "lodash";

import FormInput from "../components/common/FormInput";
import CommonInput from "../components/common/CommonInput";
import FormSelect from "../components/common/Select";
import HtmlEditor from "../components/common/HtmlEditor";
import CommonDropdownSelect from '../components/common/CommonDropdownSelect';
import FormDatePicker from "../components/common/FormDatePicker";
import FormInputSelect from "../components/common/FormInputSelect";
import FormDropdownSelect from "../components/common/FormDropdownSelect";
import Images from "../themes/Images";
import "./ExamCreate.less";
import { showMessage } from "../utils/message";

const abilityList = [
  {
    id: 1,
    name: "A1",
  },
  {
    id: 2,
    name: 'A2',
  },
  {
    id: 3,
    name: 'B1',
  },
  {
    id: 4,
    name: 'B2',
  },
  {
    id: 5,
    name: 'C1',
  },
  {
    id: 6,
    name: 'C2',
  },
];
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const ExamCreate = (props) => {
  let navigate = useNavigate();
  const createForm = useRef(null);
  const [formValue, setFormValue] = useState({
    abilities: [],
    tags: [],
    exam_desc: '',
    show_answer: false,
    show_score: false,
    show_detail: false,
    is_random: false,
    is_practice: false,
  });
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [startRequired, setStartRequired] = useState(false);
  const [endRequired, setEndRequired] = useState(false);
  const [key, setKey] = useState(1);
  const [items, setItems] = useState([]);

  useEffect(() => {
    props.getTagFormList(7);
    props.getTopicTypeFormList();
    props.getSchoolListByForm({}, setIsLoading);

    props.getCourseFormList();
  }, []);
  
  const handleAddTags = (value, dataCallback) => {
    const { handleCreateTag, saveTagList } = props;
    let payload = {
      tag_name: value,
      tag_type_id: 7,
    }
    const callback = (data) => {
      saveTagList(data);
      dataCallback();

      let temp = [];
      if (formValue.tags === undefined) {
        temp = [data.tag_id];
      } else {
        temp = [...formValue.tags, data.tag_id];
      }
      createForm.current.setFieldsValue({ tags: temp});
      setFormValue({
        ...formValue,
        tags: temp,
      });
    }
    handleCreateTag(payload, setIsLoading, callback);
  }
 
  const handleCancel = () => {
    Swal.fire({
      title: props.i18n.update_cancel,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: '#42C1B4',
    }).then((result) => {
      if (result.value) {
        navigate('/exam');
      }
    });
  }


  const onSortEnd = ({ oldIndex, newIndex }) => {
    let temp = JSON.parse(JSON.stringify(items));
    let tempData = arrayMoveImmutable(temp, oldIndex, newIndex);

    setItems(tempData);
    setKey(newIndex);
  };

  const handleChangeItemsKey = (value, index, key) => {
    let temp = JSON.parse(JSON.stringify(items));
    temp[index][key] = value;
    setItems(temp);
  }

  const handleRemovePartItem = (itemIndex, index) => {
    let temp = JSON.parse(JSON.stringify(items));
    temp[itemIndex].part_items = temp[itemIndex].part_items.filter((child, childIndex) => childIndex !== index);
    setItems(temp);
  }

  const handlePartItemChange = (value, itemIndex, index, key) => {
    let temp = JSON.parse(JSON.stringify(items));
    temp[itemIndex].part_items[index][key] = value;
    setItems(temp);
  }

  const handleAddPartItem = (key) => {
    let temp = JSON.parse(JSON.stringify(items));
    if (formValue.is_random) {
      temp[key].part_items.push({
        part_item_type: undefined,
        topic_total: 0,
      });
    } else {
      temp[key].part_items.push({
        part_item_type: undefined,
        topic_total: 0,
        topic_count: 0
      });
    }
    setItems(temp);
  }

  const handleAddItems = () => {
    let temp = JSON.parse(JSON.stringify(items));
    temp.push({
      part_title: `第${temp.length + 1}大題`,
      part_desc: '',
      part_time: '',
      video_time: 1,
      is_choose: false,
      tags: formValue.tags,
      abilities: formValue.abilities,
      part_items: [],
    });
    setItems(temp);
    setKey(temp.length -1);
  }

  const handleRemoveItem = (e, index) => {
    e.stopPropagation();
    setKey(0);
    let temp = JSON.parse(JSON.stringify(items));
    temp = temp.filter((child, childIndex) => childIndex !== index);
    setTimeout(() => {
      setItems(temp);
    }, 300);
  }

  const SortableItem = SortableElement(({value}) => <div style={{
      height: '53px',
      width: '100%',
      background: 'linear-gradient(to right, #B6E5D4, #BBFFE6 60%, #FFFFFF)',
      marginBottom: '2px',
      color: props.color.leftMenuText,
      fontSize: '25px',
      lineHeight: '53px',
      cursor: 'pointer',
      display: 'flex',
      opacity: value.key === value.index ? 1 : 0.51,
      zIndex: 1,
  }}
    onClick={() => {
      setKey(value.index)
    }}
  >
    <div style={{ display: 'flex', width: '100%'}}>
      <div style={{
        width: '66px',
        textAlign: 'right',
        fontSize: '29px',
        color: props.color.leftMenuText,
        borderLeft: value.key === value.index ? '8px solid #0BA296': '8px solid transparent',
      }}>
        {(JSON.stringify(value.index+1)).padStart(2, '0')}.
      </div>
      <div style={{
        fontSize: '25px',
        color: props.color.leftMenuText,
      }}>
        {value.value.part_title}
      </div>
    </div>
    <div style={{ minWidth: '60px', marginRight: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Button
        style={{
          width: '40px',
          height: '40px',
          backgroundColor: '#D53838',
          border: `1px solid #F31616`,
          borderRadius: '15px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 111,
        }}
        disabled={items.length === 1}
        onClick={e => handleRemoveItem(e, value.index)}
      >
        <img alt="delete" src={Images.delete} style={{ width: '19.4px', height: '19.4px'}} />
      </Button>
    </div>
  </div>);

  const SortableList = SortableContainer(({items}) => {
    return (
      <div>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={{ value, index, key }}
          />
        ))}
      </div>
    );
  });
  
  const handleFormChange = (change, all) => {
    if (_.has(change, 'is_big')) {
      setStartRequired(change.is_big);
      setEndRequired(change.is_big);
    }
    if (_.has(change, 'is_pvqc')) {
      setFormValue({
        ...formValue,
        is_big: false,
      });
      createForm.current.setFieldsValue({ is_big: false });
    }
    if (_.has(change, 'is_big')) {
      setFormValue({
        ...formValue,
        is_pvqc: false,
      });
      createForm.current.setFieldsValue({ is_pvqc: false });
    }
    setFormValue({
      ...formValue,
      ...all,
    });
  }

  const handleChangeContent = (value) => {
    // setHtmlText(value.toHTML());
  }

  const handleFinish = (value) => {
    if (step === 1) {
      let tempPart = [];
      for (let index = 0; index < parseInt(value.part_total); index++) {
        if (index > items.length -1) {
          tempPart.push({
            part_title: `第${index + 1}大題`,
            part_desc: '',
            part_time: '',
            video_time: 1,
            is_choose: false,
            tags: [],
            abilities: [],
            part_items: [],
          });
        } else {
          tempPart.push(items[index]);
        }
      }
      setKey(0);
      setItems(tempPart);
      setStep(2);
      props.getTagFormList(6);
    } else {
      let check = true;
      let errorMessage = '';

      items.map((item, index) => {
        if (!item.is_choose) {
          if (item.topic_score === "" || item.topic_score === '0' || item.topic_score === undefined) {
            check = false;
            errorMessage += `第${index+1}大題分數不可為0或為空，`;
          }
          if (item.part_items.length === 0) {
            check = false;
            errorMessage += `第${index + 1}大題題型不可為空，`;
          }
          item.part_items.map((child) => {
            if (child.part_item_type === undefined || child.part_item_type === "") {
              check = false;
              errorMessage += `第${index + 1}大題題型不可為空，`;
            }
            if (value.is_random) {
              if (child.topic_total === 0 || child.topic_total === '') {
                check = false;
                errorMessage += `第${index + 1}大題總題庫數量不可為0，`;
              }
            }
            if (child.topic_count === 0 || child.topic_count === '') {
              check = false;
              errorMessage += `第${index + 1}大題出題數量不可為0，`;
            }
          });
        }
      })


      if (check) {
        let tempParts = [];
        items.map((item) => {
          let tempPartItems = [];
          item.part_items.map((child) => {
            tempPartItems.push({
              ...child,
              topic_score: item.topic_score,
            });
          });
          tempParts.push({
            ...item,
            part_time: (item.part_time === '' || _.isNil(item.part_time)) ? 0 : item.part_time,
            video_time: (item.video_time === '' || _.isNil(item.video_time)) ? 1 : parseInt(item.video_time),
            topic_count: parseInt(item.topic_count),
            topic_score: parseFloat(item.topic_score),
            parts: tempPartItems,
          });
        });

        let payload = {
          ...formValue,
          schools: formValue.schools !== undefined ? formValue.schools: [],
          exam_desc: _.isObject(formValue.exam_desc) ? formValue.exam_desc.toHTML(): formValue.exam_desc,
          parts: tempParts,
        };
        const callback = (value) => {
          navigate(`/exam/update/${value.exam_id}`);
        }
        props.handleCreateExam(payload, setIsLoading, callback);
      } else {
        showMessage({ type: 'warning', content: errorMessage });
      }
    }
  }


  return <div style={{ height: `${props.screenHeight - 126}px`, marginRight: '16px', paddingBottom: '8px'}} className="scrollList">
    <Form
      {...layout}
      ref={createForm}
      name="basic"
      initialValues={{
      }}
      onValuesChange={handleFormChange}
      onFinish={handleFinish}
    >
      <Row gutter={24}>
        <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '64px', height: '64px', border: '2px dashed #26695C', backgroundColor: step === 1 ? '#C4FEEE': 'transparent', borderRadius: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={Images.one} style={{ width: '18px', height: '36px' }} alt="1" />
          </div>
          <div style={{ width: '327px', height: '1px', backgroundColor: '#707070', margin: '0px 30px'}}></div>
          <div style={{ width: '64px', height: '64px', border: '2px dashed #26695C', backgroundColor: step === 2 ? '#C4FEEE' :'transparent', borderRadius: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={Images.two} style={{ width: '18px', height: '36px' }} alt="1" />
          </div>
        </Col>
        {step === 1 && <Col span={16}>
          <FormInput
            title={props.i18n.exam_title}
            name="exam_title"
            required
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_title}`}
          />
        </Col>}
        {step === 1 && <Col span={4}>
          <FormInput
            title={props.i18n.origin_price}
            name="exam_price"
            required
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.origin_price}`}
          />
        </Col>}
        {step === 1 && <Col span={4}>
          <FormInput
            title={props.i18n.discount_price}
            name="exam_discount"
            type="number"
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.discount_price}`}
          />
        </Col>}
        {step === 1 && <Col span={24}>
          <HtmlEditor
            title={props.i18n.exam_desc}
            i18nMode={props.i18nMode}
            propName="exam_desc"
            // value={formValue.topic_title}
            textColor={props.color.inputTitleColor}
            onEditorStateChange={handleChangeContent}
          />
        </Col>}
        {step === 1 && <Col span={8}>
          <FormDropdownSelect
            name="tags"
            style={{ width: '100% !important'}}
            mode="multiple"
            type="array"
            options={props.formTagList}
            allowClear={true}
            title={props.i18n.character_tag}
            textColor={props.color.inputTitleColor}
            handleAddItem={handleAddTags}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_tag}`}
          />
        </Col>}
        {step === 1 && <Col span={8}>
          <FormInputSelect
            name="abilities"
            mode="multiple"
            type="array"
            options={abilityList}
            allowClear={true}
            title={props.i18n.character_ability}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.character_ability}`}
          />
        </Col>}
        {step === 1 && <Col span={8}>
          <FormInput
            name="practice_times"
            type="number"
            title={props.i18n.exam_time}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_time}`}
          />
        </Col>}
        {step === 1 && <Col span={8}>
          <FormDatePicker
            title={props.i18n.start_time}
            required={startRequired}
            name="exam_start"
            showTime={{
              defaultValue: moment('00:00:00', 'HH:mm:ss')
            }}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.start_time}`}
          />
        </Col>}
        {step === 1 && <Col span={8}>
          <FormDatePicker
            title={props.i18n.end_time}
            required={endRequired}
            name="exam_end"
            showTime={{
              defaultValue: moment('23:59:59', 'HH:mm:ss')
            }}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.end_time}`}
          />
        </Col>}
        {step === 1 && <Col span={8}>
          <FormInput
            name="part_total"
            type="number"
            required
            title={props.i18n.exam_page}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_page}`}
          />
        </Col>}
        {step ===1 && <Col span={8}>
          <FormInputSelect
            name="schools"
            mode="multiple"
            type="array"
            options={props.schoolFormList}
            allowClear={true}
            title={props.i18n.lottery_act_school}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.lottery_act_school}`}
          />
        </Col>}
        {step ===1 && <Col span={8}>
          <FormInputSelect
            name="roles"
            mode="multiple"
            type="array"
            options={[{id: 'M004', name: '學士'}, {id: 'M005', name: '碩士'}]}
            allowClear={true}
            title={props.i18n.exam_role}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_role}`}
          />
        </Col>}
        {step ===1 && <Col span={8}>
          <FormInputSelect
            name="courses"
            mode="multiple"
            type="array"
            options={props.courseFormList}
            allowClear={true}
            title={props.i18n.exam_course}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.exam_course}`}
          />
        </Col>}
        {step === 1 && <Col span={24} style={{ display: 'flex', alignItems: 'flex-start'}}>
          <Form.Item name="is_practice" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
          <span
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginTop: '4px',
              marginLeft: '16px',
              marginRight: '30px'
            }}
          >{props.i18n.exam_is_exam}</span>
          <Form.Item name="is_random" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
          <span
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginTop: '4px',
              marginLeft: '16px',
              marginRight: '30px'
            }}
          >{props.i18n.exam_is_fix}</span>
          <Form.Item name="show_answer" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
          <span
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginTop: '4px',
              marginLeft: '16px',
              marginRight: '30px'
            }}
          >{props.i18n.exam_show_answer}</span>
          <Form.Item name="show_score" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
          <span
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginTop: '4px',
              marginLeft: '16px',
              marginRight: '30px'
            }}
          >{props.i18n.exam_show_score}</span>
          <Form.Item name="show_detail" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
          <span
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginTop: '4px',
              marginLeft: '16px',
              marginRight: '30px'
            }}
          >{props.i18n.exam_show_detail}</span>
          <Form.Item name="is_pvqc" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
          <span
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginTop: '4px',
              marginLeft: '16px',
              marginRight: '30px'
            }}
          >{props.i18n.exam_show_pvqc}</span>
          <Form.Item name="is_big" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
          <span
            style={{
              color: props.color.inputTitleColor,
              fontSize: '18px',
              fontWeight: 'bold',
              marginTop: '4px',
              marginLeft: '16px'
            }}
          >{props.i18n.exam_is_big}</span>
        </Col>}
        {step === 2 && <Col span={24}>
          <div
            style={{
              color: props.color.inputTitleColor,
              fontSize: '24px',
              fontWeight: 'bold',
              marginTop: '4px',
              marginLeft: '16px',
              marginRight: '50px'
            }}
          >{props.i18n.exam_page_setting}</div>
        </Col>}
        {step === 2 && <Col lg={8} xxl={6}>
          <div style={{ backgroundColor: '#FFF', borderRadius: '15px', padding: '12px 0px'}}>
            <div style={{ padding: '0px 30px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: '24px',
                  fontWeight: 'bold',
                }}
              >{props.i18n.exam_page_list}</div>
              <div
                style={{
                  minWidth: '60px',
                  color: props.color.inputTitleColor,
                  fontSize: '24px',
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
              >{props.i18n.operation}</div>
            </div>
            <SortableList
              items={items}
              onSortEnd={onSortEnd}
              distance={1}
            />
            <div
              style={{ 
                height: '50px',
                // width: '100%',
                margin: '16px 30px',
                border: '3px dashed #0EA285',
                borderRadius: '15px',
                fontSize: '18px',
                color: '#26695C',
                textAlign: 'center',
                lineHeight: '44px'
              }}
              onClick={handleAddItems}
            >
              + {props.i18n.create_btn}
            </div>
          </div>
        </Col>}
        {step === 2 && <Col lg={16} xxl={18}>
          <Row gutter={24}>
            <Col span={12}>
              <div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: '18px',
                  padding: '0px 16px',
                  fontWeight: 'bold',
                  marginBottom: '8px',
                  height: '32px',
                  lineHeight: '32px'
                }}>{props.i18n.exam_page_title}
              </div>
              <CommonInput
                value={items[key].part_title}
                style={{ marginBottom: '16px'}}
                onChange={(e) => handleChangeItemsKey(e.target.value, key, 'part_title')}
                placeholder={`${props.i18n.placeholder}${props.i18n.exam_page_title}`}
              />
            </Col>
            <Col span={12}>
              <div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: '18px',
                  padding: '0px 16px',
                  fontWeight: 'bold',
                  marginBottom: '8px',
                  height: '32px',
                  lineHeight: '32px'
                }}>
                <span style={{ color: 'red', marginRight: '8px' }}>*</span>{props.i18n.exam_page_score}
              </div>
              <CommonInput
                title={props.i18n.exam_page_score}
                style={{ marginBottom: '16px'}}
                value={items[key].topic_score}
                onChange={(e) => handleChangeItemsKey(e.target.value, key, 'topic_score')}
                textColor={props.color.inputTitleColor}
                placeholder={`${props.i18n.placeholder}${props.i18n.exam_page_score}`}
              />
            </Col>
            <Col span={12}>
              <div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: '18px',
                  padding: '0px 16px',
                  fontWeight: 'bold',
                  marginBottom: '8px',
                  height: '32px',
                  lineHeight: '32px'
                }}>{props.i18n.character_tag}
              </div>
              <CommonDropdownSelect
                mode="multiple"
                type="array"
                value={items[key].tags}
                onChange={(e) => handleChangeItemsKey(e, key, 'tags')}
                options={props.formTagList}
                allowClear={true}
                handleAddItem={handleAddTags}
                placeholder={`${props.i18n.placeholder}${props.i18n.character_tag}`}
              />
            </Col>
            <Col span={12}>
              <div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: '18px',
                  padding: '0px 16px',
                  fontWeight: 'bold',
                  marginBottom: '8px',
                  height: '32px',
                  lineHeight: '32px'
                }}>{props.i18n.character_ability}
              </div>
              <FormSelect
                allowClear={true}
                showSearch
                style={{ width: '100%'}}
                mode="multiple"
                placeholder={`${props.i18n.filter}${props.i18n.character_ability}`}
                options={abilityList}
                value={items[key].abilities}
                onChange={(e) => handleChangeItemsKey(e, key, 'abilities')}
              />
            </Col>
            <Col span={12} style={{marginTop: '16px'}}>
              <div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: '18px',
                  padding: '0px 16px',
                  fontWeight: 'bold',
                  marginBottom: '8px',
                  height: '32px',
                  lineHeight: '32px'
                }}>
                {props.i18n.exam_time}
              </div>
              <CommonInput
                // title={props.i18n.exam_page_score}
                // style={{ marginBottom: '16px'}}
                value={items[key].part_time}
                type="number"
                onChange={(e) => handleChangeItemsKey(e.target.value, key, 'part_time')}
                textColor={props.color.inputTitleColor}
                placeholder={`${props.i18n.placeholder}${props.i18n.exam_time}`}
              />
            </Col>
            <Col span={12} style={{marginTop: '16px'}}>
              <div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: '18px',
                  padding: '0px 16px',
                  fontWeight: 'bold',
                  marginBottom: '8px',
                  height: '32px',
                  lineHeight: '32px'
                }}>
                <span style={{ color: 'red', marginRight: '8px' }}>*</span>{props.i18n.exam_page_video_time}
              </div>
              <CommonInput
                // title={props.i18n.exam_page_score}
                // style={{ marginBottom: '16px'}}
                value={items[key].video_time}
                type="number"
                required
                requiredErrorMessage={`${props.i18n.placeholder}${props.i18n.exam_page_video_time}`}
                onChange={(e) => handleChangeItemsKey(e.target.value, key, 'video_time')}
                textColor={props.color.inputTitleColor}
                placeholder={`${props.i18n.placeholder}${props.i18n.exam_page_video_time}`}
              />
            </Col>
            <Col span={24} style={{ marginTop: '16px'}}>
              <HtmlEditor
                title={props.i18n.part_desc}
                i18nMode={props.i18nMode}
                // propName="part_desc"
                // value={formValue.topic_title}
                textColor={props.color.inputTitleColor}
                onEditorStateChange={(e) => handleChangeItemsKey(e.toHTML(), key, 'part_desc')}
                value={items[key].part_desc}
              />
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: 'flex',
                  width: '180px',
                  borderRadius: '15px',
                  backgroundColor: '#42C1B4',
                  border: '1px solid #C1EFD6',
                  height: '40px',
                  overflow: 'hidden',
                }}>
                <div
                  style={{
                    width: '89px',
                    borderRight: '1px solid #26695C',
                    lineHeight: '40px',
                    textAlign: 'center',
                    color: 'white',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    background: !items[key].is_choose ?'linear-gradient(to bottom, #51C6BA, #004E46)': 'transparent',
                  }}
                  onClick={(e) => handleChangeItemsKey(false, key, 'is_choose')}
                >{props.i18n.exam_auto}</div>
                <div
                  style={{
                    width: '89px',
                    borderLeft: '1px solid #2DA498',
                    lineHeight: '40px',
                    textAlign: 'center',
                    color: 'white',
                    fontSize: '18px',
                    fontWeight: 'bold',
                      cursor: 'pointer',
                    background: items[key].is_choose ?'linear-gradient(to bottom, #51C6BA, #004E46)': 'transparent',
                  }}
                  onClick={(e) => handleChangeItemsKey(true, key, 'is_choose')}
                >{props.i18n.exam_custom}</div>
              </div>
            </Col>
            {items[key].is_choose && <Col span={24}>
              <div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: '18px',
                  padding: '0px 16px',
                  fontWeight: 'bold',
                  marginBottom: '8px',
                  height: '32px',
                  lineHeight: '32px'
                }}>
                <span style={{ color: 'red', marginRight: '8px' }}>*</span>{props.i18n.exam_page_topic_count}
              </div>
              <CommonInput
                title={props.i18n.exam_page_topic_count}
                style={{ marginBottom: '16px'}}
                value={items[key].topic_count}
                onChange={(e) => handleChangeItemsKey(e.target.value, key, 'topic_count')}
                textColor={props.color.inputTitleColor}
                placeholder={`${props.i18n.placeholder}${props.i18n.exam_page_topic_count}`}
              />
            </Col>}
            {!items[key].is_choose && <Col span={24} style={{ marginTop: '16px'}}>
              <div
                style={{
                  fontSize: '18px',
                  color: props.color.inputTitleColor,
                  fontWeight: 'bold',
                  marginLeft: '16px',
                }}>{props.i18n.exam_page_content}</div>
            </Col>}
            {!items[key].is_choose && <Col span={24}>
              <div style={{
                width: '100%',
                backgroundColor: props.color.currentTheme === 'dark' ? '#002C51' :'#C2EBE8',
                borderRadius: '20px',
                height: '70px',
                padding: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '16px',
              }}>
                <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%'}}>{props.i18n.exam_page_topic_type}</div>
                {formValue.is_random && <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%'}}>{props.i18n.exam_page_topic_store}</div>}
                <div style={{ fontSize: '18px', color: props.color.textColor, width: '100%'}}>{props.i18n.exam_page_topic_count}</div>
                <div style={{ fontSize: '18px', color: props.color.textColor, minWidth: '60px', textAlign: 'center'}}>{props.i18n.operation}</div>
              </div>
              {items[key].part_items.map((item, index) => {
                  return <div style={{ display: 'flex', width: '100%',padding: '0px 16px 0px 8px', marginBottom: '16px'}}>
                    <FormSelect
                      options={[{id: '-1', name: '綜合題'},...props.formTopicTypeList]}
                      allowClear={true}
                      type="text"
                      style={{ width: '100%', marginRight: '16px'}}
                      value={item.part_item_type}
                      onChange={(e) => handlePartItemChange(e, key, index , 'part_item_type')}
                      placeholder={`${props.i18n.placeholder}${props.i18n.exam_page_topic_type}`}
                    />
                    {formValue.is_random && <CommonInput
                      type="number"
                      value={item.topic_total}
                      onChange={(e) => handlePartItemChange(e.target.value, key, index , 'topic_total')}
                      formItemStyle={{ marginRight: '16px'}}
                      style={{ width: '100%', marginRight: '16px'}}
                      textColor={props.color.inputTitleColor}
                      placeholder={`${props.i18n.placeholder}${props.i18n.exam_page_topic_store}`}
                    />}
                    <CommonInput
                      title=""
                      type="number"
                      value={item.topic_count}
                      onChange={(e) => handlePartItemChange(e.target.value, key, index , 'topic_count')}
                      formItemStyle={{ marginRight: '16px'}}
                      style={{ width: '100%', marginRight: '16px'}}
                      textColor={props.color.inputTitleColor}
                      placeholder={`${props.i18n.placeholder}${props.i18n.exam_page_topic_count}`}
                    />
                    <div style={{ minWidth: '60px', height: '50px', display: 'flex', alignItems: 'center'}}>
                      <Button
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '15px',
                          width: '40px',
                          height: '40px',
                          backgroundColor: '#D53838',
                          border: '1px solid #F31616',
                          marginLeft: '8px',
                        }}
                        onClick={() => handleRemovePartItem(key, index)}
                      >
                        <img
                          src={Images.delete}
                          style={{ width: '22px', height: '14px'}}
                          alt={props.i18n.delete}
                        />
                      </Button>
                    </div>
                  </div>
                })
              }
              <Button
                type="dashed"
                onClick={() => handleAddPartItem(key)}
                block
                style={{
                  fontSize: '18px',
                  border: '2px dashed #0EA285',
                  height: '50px',
                  lineHeight: '50px',
                  padding: '0px',
                  color: props.color.currentTheme === 'dark'? '#00FFCE': '#26695C',
                  backgroundColor: 'transparent',
                  borderRadius: '15px',
                }}
              >
                + {props.i18n.create_btn}
              </Button>
            </Col>}
          </Row>

        </Col>}
        <Col span={24} style={{ marginTop: '16px'}}>
          <Form.Item style={{ width: '100%', textAlign: 'center'}}>
            {step === 1 && <Button
              loading={isLoading}
              htmlType="submit"
              style={{
                height: '50px',
                backgroundColor: '#4A9E8F',
                width: '128px',
                borderRadius: '20px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                marginRight: '30px'
              }}
              // onClick={() => setStep(2)}
            >
              {props.i18n.next_step}
            </Button>}
            {step === 2 && <Button
              loading={isLoading}
              htmlType="submit"
              style={{
                height: '50px',
                backgroundColor: '#4A9E8F',
                width: '128px',
                borderRadius: '20px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                marginRight: '30px'
              }}
              onClick={() => {
                const callback = () => {
                  setStep(1);
                }
                props.getTagFormList(7, setIsLoading, callback);
              }}
            >
              {props.i18n.prev_step}
            </Button>}
            {step === 2 && <Button
              loading={isLoading}
              htmlType="submit"
              style={{
                height: '50px',
                backgroundColor: '#4A9E8F',
                width: '128px',
                borderRadius: '20px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' :'2px solid #C7E3D4',
                marginRight: '30px'
              }}
            >
              {props.i18n.create_btn}
            </Button>}
            <Button
              loading={isLoading}
              onClick={handleCancel}
              style={{
                height: '50px',
                backgroundColor: '#D53838',
                width: '128px',
                borderRadius: '20px',
                color: 'white',
                border: props.color.currentTheme === 'dark' ? 'none' : '2px solid #C7E3D4'
              }}
            >
            {props.i18n.swal_no}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </div>;
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, 'Global.theme', 'light'),
    themeMode: _.get(state, 'Global.themeMode', 'dark'),
    color: _.get(state, 'Global.color', {}),
    i18n: _.get(state, 'Global.i18n', {}),
    i18nMode: _.get(state, 'Global.i18nMode', {}),
    screenHeight: _.get(state, 'Global.screenHeight', 0),
    examList: _.get(state, 'Exam.examList', []),
    paging: _.get(state, 'Exam.paging', {}),
    formTagList: _.get(state, 'Tag.formTagList', []),
    formTopicTypeList: _.get(state, 'TopicManager.topicTypeFormList', []),
    schoolFormList: _.get(state, 'School.schoolFormList', []),
    courseFormList: _.get(state, 'Course.courseFormList', []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExamList(payload, loading, callback) {
      dispatch({ type: 'GET_EXAM_LIST', payload, loading, callback});
    },
    getCourseFormList(payload, loading, callback) {
      dispatch({ type: 'GET_COURSE_FORM_LIST', payload, loading, callback});
    },
    handleDeleteExam(payload, loading, callback, getData) {
      dispatch({ type: 'HANDLE_DELETE_EXAM', payload, loading, callback, getData});
    },
    getExamById(payload, loading, callback) {
      dispatch({ type: 'GET_EXAM_BY_ID', payload, loading, callback});
    },
    getTagFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TAG_FORM_LIST', payload, loading, callback});
    },
    handleCreateTag(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_TAG', payload, loading, callback});
    },
    handleCreateExam(payload, loading, callback) {
      dispatch({ type: 'HANDLE_CREATE_EXAM', payload, loading, callback});
    },
    saveTagList(payload) {
      dispatch({ type: 'SAVE_TAG_LIST', payload});
    },
    getTopicTypeFormList(payload, loading, callback) {
      dispatch({ type: 'GET_TOPIC_TYPE_FORM_LIST', payload, loading, callback});
    },
    getSchoolListByForm(payload, loading, callback) {
      dispatch({ type: 'GET_SCHOOL_LIST_BY_FORM', payload, loading, callback});
    },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamCreate);
