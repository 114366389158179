import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Drawer,
  Row,
  Col,
  Form,
  Pagination,
  Select,
  Upload,
  Checkbox,
} from "antd";
import Search from "../components/common/Search";
import FormInput from "../components/common/FormInput";
import CommonInput from "../components/common/CommonInput";
import FormSelect from "../components/common/Select";
import HtmlEditor from "../components/common/HtmlEditor";
import FormInputSelect from "../components/common/FormInputSelect";
import FormDropdownSelect from "../components/common/FormDropdownSelect";
import Images from "../themes/Images";
import { PlusOutlined } from "@ant-design/icons";
import { showMessage } from "../utils/message";
import Swal from "sweetalert2";
import ReactPlayer from "react-player";

import "./TopicManager.less";
import _ from "lodash";

let timer;
let optionList = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const normFile = (e) => {
  let list = e.fileList;
  if (list.length >= 2) {
    list.slice(1);
    e.fileList = list;
  }
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const abilityList = [
  {
    id: 1,
    name: "A1",
  },
  {
    id: 2,
    name: "A2",
  },
  {
    id: 3,
    name: "B1",
  },
  {
    id: 4,
    name: "B2",
  },
  {
    id: 5,
    name: "C1",
  },
  {
    id: 6,
    name: "C2",
  },
];

const sizeList = [
  {
    id: 12,
    name: 12,
  },
  {
    id: 24,
    name: 24,
  },
  {
    id: 48,
    name: 48,
  },
];

const TopicManager = (props) => {
  let navigate = useNavigate();
  const createForm = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState({});
  const [currentTopicType, setCurrentTopicType] = useState({});
  const [htmlText, setHtmlText] = useState("");
  const [topicType, setTopicType] = useState(undefined);
  const [mode, setMode] = useState("create");
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [items, setItems] = useState([]);
  const [itemImage, setItemImage] = useState([]);
  const [itemAudio, setItemAudio] = useState([]);
  const [optionFile, setOptionFile] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const [tag, setTag] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [ability, setAbility] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    handleGetData();
    props.getTagFormList(6);
    props.getTopicTypeFormList();
    props.getTopicTypeList();
    props.getTopicGroupListByForm();
  }, []);

  const handleGetData = () => {
    let param = new URLSearchParams(window.location.search);
    let payload = {
      type: null,
      search: "",
      page: 1,
      tags: [],
      abilities: [],
      pageCount: 12,
      orderKeys: [],
    };
    if (param.get("search") !== null) {
      setSearch(param.get("search"));
      payload.search = param.get("search");
    }
    if (param.get("page") !== null) {
      payload.page = param.get("page");
    }
    if (param.get("pageCount") !== null) {
      payload.pageCount = param.get("pageCount");
    }
    if (param.get("topicType") !== null && param.get("topicType") !== "") {
      payload.type = param.get("topicType");
    }
    if (param.get("tag") !== null && param.get("tag") !== "") {
      let temp = param.get("tag").split(",");
      if (temp.length > 0) {
        temp.map((item, index) => {
          temp[index] = parseInt(item);
        });
      }
      payload.tags = temp;
      setTag(temp);
    }
    if (param.get("ability") !== null && param.get("ability") !== "") {
      let tempAbility = param.get("ability").split(",");
      if (tempAbility.length > 0) {
        tempAbility.map((item, index) => {
          tempAbility[index] = parseInt(item);
        });
      }
      payload.abilities = tempAbility;
      setAbility(tempAbility);
    }
    props.getTopicList(payload, setIsLoading);
  };

  useEffect(() => {
    handleGetData();
  }, [window.location.href]);

  const handlePreview = (file) => {
    if (_.has(file, "thumbUrl")) {
      const imgWindow = window.open(file.thumbUrl);
      imgWindow.document.write(`<img src="${file.thumbUrl}">`);
    } else {
      window.open(file.url);
    }
  };
  const handleImageChange = ({ fileList: newFileList }, key) => {
    if (key === "img") {
      if (newFileList.length > 1) {
        setImageData(newFileList.splice(1, 1));
      } else {
        setImageData(newFileList);
      }
    } else {
      if (newFileList.length > 1) {
        setAudioData(newFileList.splice(1, 1));
      } else {
        setAudioData(newFileList);
      }
    }
  };
  const handleTopicFileChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 1) {
      setTopicData(newFileList.splice(1, 1));
    } else {
      setTopicData(newFileList);
    }
  };

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileData(newFileList);
  };
  const handleShowUpdate = (data) => {
    const { getTopicById } = props;
    const callback = (value) => {
      setMode("update");
      setCurrentTopicType(value.topic_type);
      let tempTag = [];
      value.tags.map((item) => {
        tempTag.push(item.tag_id);
      });
      let tempAbility = [];
      value.abilities.map((item) => {
        tempAbility.push(item.ability_id);
      });
      if (value.imgs !== null && value.imgs.length > 0) {
        setImageData([
          {
            uid: value.imgs[0].file_id,
            name: "img",
            status: "done",
            url: value.imgs[0].file_url,
          },
        ]);
      }
      if (value.audio !== null && value.audio.length > 0) {
        setAudioData([
          {
            uid: value.audio[0].file_id,
            name: "audio",
            status: "done",
            url: value.audio[0].file_url,
          },
        ]);
      }
      if (value.options !== null) {
        let temp = [];
        let tempFile = [];
        value.options.map((item, index) => {
          if (value.topic_type.have_option_file) {
            temp.push({
              ...item,
              img: [
                {
                  uid: "1",
                  name: "file",
                  status: "done",
                  url: item.topic_option_img,
                },
              ],
            });
            tempFile.push([
              {
                uid: "1",
                name: "file",
                status: "done",
                url: item.topic_option_img,
              },
            ]);
          } else {
            temp.push(item);
          }
        });
        setOptionFile(tempFile);
        setOptions(temp);
      }
      if (value.items !== null) {
        setItems(value.items);
        let tempAudio = [];
        let tempImage = [];
        value.items.map((item) => {
          tempAudio.push(
            item.audio !== null && item.audio.length > 0
              ? [
                  {
                    uid: item.audio[0].file_id,
                    name: "audio",
                    status: "done",
                    url: item.audio[0].file_url,
                  },
                ]
              : []
          );
          tempImage.push(
            item.imgs !== null && item.imgs.length > 0
              ? [
                  {
                    uid: item.imgs[0].file_id,
                    name: "imgs",
                    status: "done",
                    url: item.imgs[0].file_url,
                  },
                ]
              : []
          );
        });
        setItemAudio(tempAudio);
        setItemImage(tempImage);
      }

      let payload = {
        ...value,
        tags: tempTag,
        abilities: tempAbility,
        topic_type_id: value.topic_type.topic_type_id,
        topic_group_id:
          value.topic_group !== null
            ? value.topic_group.topic_group_id
            : undefined,
      };
      setCurrentData(payload);
      setFormValue(payload);
      setTimeout(() => {
        setDrawerVisible(true);
      }, 100);
    };
    getTopicById(data.topic_id, setIsLoading, callback);
  };

  const handleChangeOptionName = (value, index) => {
    // 更改選項名稱
    let temp = JSON.parse(JSON.stringify(options));
    temp[index].topic_option_name = value;
    setOptions(temp);
  };
  const handleChangeOptionFile = (e, index) => {
    let temp = JSON.parse(JSON.stringify(options));
    let tempOption = { ...optionFile };
    if (e.fileList.length === 0) {
      tempOption[index] = [];
      temp[index].img = [];
    } else {
      tempOption[index] = [e.fileList[e.fileList.length - 1]];
      temp[index].img = [e.fileList[e.fileList.length - 1]];
    }
    setOptions(temp);
    setOptionFile(tempOption);
  };

  const handleRemoveOptions = (index) => {
    // 移除選項
    let temp = JSON.parse(JSON.stringify(options));
    temp = temp.filter((item, itemIndex) => itemIndex !== index);
    setOptions(temp);
  };

  const handleChangeOptionCheckbox = (checked, index) => {
    // 選項答案 checkbox
    let temp = JSON.parse(JSON.stringify(options));
    temp.map((item, itemIndex) => {
      if (index === itemIndex) {
        temp[itemIndex].is_answer = checked;
      } else {
        temp[itemIndex].is_answer = false;
      }
    });
    setOptions(temp);
  };

  const handleOptionsSort = (index, key) => {
    // 選項排序
    let temp = JSON.parse(JSON.stringify(options));
    let tempA;
    tempA = temp[index];
    temp[index] = temp[index + key];
    temp[index + key] = tempA;
    setOptions(temp);
  };

  const handleCreate = (value) => {
    const { handleCreateTopic, paging } = props;
    const callback = () => {
      setDrawerVisible(false);
      setAudioData([]);
      setImageData([]);
      setItems([]);
      setOptions([]);
      setCurrentData({});
      setFormValue({});
    };

    let check = true;
    let errorMessage = "";
    if (!currentTopicType.have_item && currentTopicType.have_select) {
      // 這情況代表沒有子題目
      let haveAnswer = false;
      options.map((item) => {
        if (item.is_answer) {
          haveAnswer = true;
        }
      });
      if (!haveAnswer) {
        check = false;
        errorMessage += "選項需設定正確答案，";
      }
    }
    if (currentTopicType.have_item && !currentTopicType.have_select) {
      items.map((item, index) => {
        if (item.topic_item_answer === "") {
          check = false;
          errorMessage += `第${index + 1}題需設定標題，`;
        }
      });
    }
    if (currentTopicType.have_item && currentTopicType.have_select) {
      items.map((item, index) => {
        let haveAnswer = false;
        let haveName = true;
        item.options.map((child) => {
          if (child.is_answer) {
            haveAnswer = true;
          }
          if (child.topic_option_name === "") {
            haveName = "";
          }
        });
        if (item.topic_item_detail === "") {
          check = false;
          errorMessage += `第${index + 1}題需設定正確答案，`;
        }
        if (!haveName) {
          check = false;
          errorMessage += `第${index + 1}題選項需設定內容，`;
        }
        if (item.options.length === 0) {
          check = false;
          errorMessage += `第${index + 1}題需設定選項，`;
        } else {
          if (!haveAnswer) {
            check = false;
            errorMessage += `第${index + 1}題選項需設定正確答案，`;
          }
        }
      });
    }

    if (check) {
      let getData = {
        search: search,
        type: topicType,
        tags: tag,
        abilities: ability,
        page: paging.page,
        pageCount: paging.pageCount,
      };

      let formData = new FormData();
      formData.append("topic_type_id", value.topic_type_id);
      if (value.topic_group_id !== undefined) {
        formData.append("topic_group_id", value.topic_group_id);
      }
      if (currentTopicType.have_img) {
        imageData.map((item) => {
          formData.append("imgs", item.originFileObj);
        });
      }
      if (currentTopicType.have_audio) {
        audioData.map((item) => {
          formData.append("audio", item.originFileObj);
        });
      }
      if (currentTopicType.have_title && value.topic_title) {
        formData.append("topic_title", value.topic_title.toHTML());
      }
      if (!currentTopicType.have_item && value.topic_detail) {
        formData.append("topic_detail", value.topic_detail.toHTML());
      }
      if (value.tags !== undefined) {
        value.tags.map((item) => {
          formData.append("tags", item);
        });
      }
      if (value.abilities !== undefined) {
        value.abilities.map((item) => {
          formData.append("abilities", item);
        });
      }
      if (currentTopicType.have_item) {
        items.map((item, index) => {
          if (currentTopicType.have_title) {
            formData.append(
              `items[${index}].topic_item_title`,
              item.topic_item_title
            );
          }
          formData.append(
            `items[${index}].topic_item_detail`,
            item.topic_item_detail
          );
          if (currentTopicType.have_img) {
            itemImage[index].map((child) => {
              formData.append(`items[${index}].imgs`, child.originFileObj);
            });
          }
          if (currentTopicType.have_audio) {
            itemAudio[index].map((child) => {
              formData.append(`items[${index}].audio`, child.originFileObj);
            });
          }
          if (currentTopicType.have_select) {
            item.options.map((child, childIndex) => {
              formData.append(
                `items[${index}].options[${childIndex}].topic_option_name`,
                child.topic_option_name
              );
              formData.append(
                `items[${index}].options[${childIndex}].is_answer`,
                child.is_answer
              );
            });
          }
        });
      } else {
        if (!currentTopicType.have_select) {
          formData.append("topic_answer", value.topic_answer);
        } else {
          options.map((child, childIndex) => {
            if (currentTopicType.have_option_file) {
              formData.append(
                `options[${childIndex}].img`,
                optionFile[childIndex][0].originFileObj
              );
            } else {
              formData.append(
                `options[${childIndex}].topic_option_name`,
                child.topic_option_name
              );
            }
            formData.append(
              `options[${childIndex}].is_answer`,
              child.is_answer
            );
          });
        }
      }

      handleCreateTopic(formData, setIsLoading, callback, getData);
    } else {
      showMessage({ type: "warning", content: errorMessage });
    }
  };

  const handleImport = (value) => {
    const { handleImportTopic, paging } = props;
    let formData = new FormData();
    formData.append("topic_type_id", value.topic_type_id);
    formData.append("topic", value.topic[0].originFileObj);
    if (_.isArray(value.files)) {
      value.files.map((item) => {
        formData.append("files", item.originFileObj);
      });
    }

    const callback = () => {
      setDrawerVisible(false);
    };

    let getData = {
      search: search,
      type: topicType,
      tags: tag,
      abilities: ability,
      page: paging.page,
      pageCount: paging.pageCount,
    };
    handleImportTopic(formData, setIsLoading, callback, getData);
  };

  const handleUpdate = (value) => {
    const { handleUpdateTopic, paging } = props;
    const callback = () => {
      setDrawerVisible(false);
      setAudioData([]);
      setImageData([]);
      setItems([]);
      setOptions([]);
      setCurrentData({});
      setFormValue({});
    };

    let check = true;
    let errorMessage = "";
    if (!currentTopicType.have_item && currentTopicType.have_select) {
      // 這情況代表沒有子題目
      let haveAnswer = false;
      options.map((item) => {
        if (item.is_answer) {
          haveAnswer = true;
        }
      });
      if (!haveAnswer) {
        check = false;
        errorMessage += "選項需設定正確答案，";
      }
    }
    if (currentTopicType.have_item && !currentTopicType.have_select) {
      items.map((item, index) => {
        if (item.topic_item_answer === "") {
          check = false;
          errorMessage += `第${index + 1}題需設定標題，`;
        }
      });
    }
    if (currentTopicType.have_item && currentTopicType.have_select) {
      items.map((item, index) => {
        let haveAnswer = false;
        let haveName = true;
        item.options.map((child) => {
          if (child.is_answer) {
            haveAnswer = true;
          }
          if (child.topic_option_name === "") {
            haveName = "";
          }
        });
        if (item.topic_item_detail === "") {
          check = false;
          errorMessage += `第${index + 1}題需設定正確答案，`;
        }
        if (!haveName) {
          check = false;
          errorMessage += `第${index + 1}題選項需設定內容，`;
        }
        if (item.options.length === 0) {
          check = false;
          errorMessage += `第${index + 1}題需設定選項，`;
        } else {
          if (!haveAnswer) {
            check = false;
            errorMessage += `第${index + 1}題選項需設定正確答案，`;
          }
        }
      });
    }

    if (check) {
      let getData = {
        search: search,
        type: topicType,
        tags: tag,
        abilities: ability,
        page: paging.page,
        pageCount: paging.pageCount,
      };

      let formData = new FormData();
      formData.append("topic_id", currentData.topic_id);
      formData.append("topic_type_id", value.topic_type_id);
      if (value.topic_group_id !== undefined) {
        formData.append("topic_group_id", value.topic_group_id);
      }
      if (currentTopicType.have_img) {
        imageData.map((item, index) => {
          if (_.has(item, "originFileObj")) {
            formData.append(`imgs[${index}].file`, item.originFileObj);
          } else {
            formData.append(`imgs[${index}].file_id`, item.uid);
          }
          formData.append(`imgs[${index}].order`, index + 1);
        });
        let i = 0;
        if (_.isArray(currentData.imgs)) {
          currentData.imgs.map((item) => {
            if (
              imageData.filter((child) => child.uid === item.file_id).length ===
              0
            ) {
              formData.append(`remove_imgs[${i}]`, item.file_id);
              i += 1;
            }
          });
        }
      }
      if (currentTopicType.have_audio) {
        audioData.map((item, index) => {
          if (_.has(item, "originFileObj")) {
            formData.append(`audio[${index}].file`, item.originFileObj);
          } else {
            formData.append(`audio[${index}].file_id`, item.uid);
          }
          formData.append(`audio[${index}].order`, index + 1);
        });
        let i = 0;
        if (_.isArray(currentData.audio)) {
          currentData.audio.map((item) => {
            if (
              audioData.filter((child) => child.uid === item.file_id).length ===
              0
            ) {
              formData.append(`remove_audio[${i}]`, item.file_id);
              i += 1;
            }
          });
        }
      }
      if (currentTopicType.have_title && value.topic_title) {
        if (_.isObject(value.topic_title)) {
          formData.append("topic_title", value.topic_title.toHTML());
        } else {
          formData.append("topic_title", value.topic_title);
        }
      }
      if (!currentTopicType.have_item && value.topic_detail) {
        if (_.isObject(value.topic_detail)) {
          formData.append("topic_detail", value.topic_detail.toHTML());
        } else {
          formData.append("topic_detail", value.topic_detail);
        }
      }

      if (value.tags !== undefined) {
        value.tags.map((item) => {
          formData.append("tags", item);
        });
      }
      if (value.abilities !== undefined) {
        value.abilities.map((item) => {
          formData.append("abilities", item);
        });
      }
      if (currentTopicType.have_item) {
        items.map((item, index) => {
          if (_.has(item, "topic_item_id")) {
            formData.append(
              `items[${index}].topic_item_id`,
              item.topic_item_id
            );
          }
          formData.append(`items[${index}].topic_item_order`, index + 1);
          if (currentTopicType.have_title) {
            if (_.isObject(item.topic_item_title)) {
              formData.append(
                `items[${index}].topic_item_title`,
                item.topic_item_title.toHTML()
              );
            } else {
              formData.append(
                `items[${index}].topic_item_title`,
                item.topic_item_title
              );
            }
          }
          if (_.isObject(item.topic_item_detail)) {
            formData.append(
              `items[${index}].topic_item_detail`,
              item.topic_item_detail.toHTML()
            );
          } else {
            formData.append(
              `items[${index}].topic_item_detail`,
              item.topic_item_detail
            );
          }
          if (currentTopicType.have_img) {
            itemImage[index].map((child, childIndex) => {
              if (_.has(child, "originFileObj")) {
                formData.append(
                  `items[${index}].imgs[${childIndex}].file`,
                  child.originFileObj
                );
                if (
                  currentData.items[index].imgs !== null &&
                  currentData.items[index].imgs.length > 0
                ) {
                  formData.append(`items[${index}].remove_imgs`, [
                    `${currentData.items[index].imgs[0].file_id}`,
                  ]);
                }
              } else {
                formData.append(
                  `items[${index}].imgs[${childIndex}].file_id`,
                  child.uid
                );
              }
              formData.append(
                `items[${index}].imgs[${childIndex}].file_order`,
                1
              );
            });
          }
          if (currentTopicType.have_audio) {
            itemAudio[index].map((child, childIndex) => {
              if (_.has(child, "originFileObj")) {
                formData.append(
                  `items[${index}].audio[${childIndex}].file`,
                  child.originFileObj
                );
                if (
                  currentData.items[index].audio !== null &&
                  currentData.items[index].audio.length > 0
                ) {
                  formData.append(`items[${index}].remove_audio`, [
                    `${currentData.items[index].audio[0].file_id}`,
                  ]);
                }
              } else {
                formData.append(
                  `items[${index}].audio[${childIndex}].file_id`,
                  child.uid
                );
              }
              formData.append(
                `items[${index}].audio[${childIndex}].file_order`,
                1
              );
            });
          }
          if (currentTopicType.have_select) {
            let i = 0;
            item.options.map((child, childIndex) => {
              if (_.has(child, "topic_option_id")) {
                formData.append(
                  `items[${index}].options[${childIndex}].topic_option_id`,
                  child.topic_option_id
                );
              }
              formData.append(
                `items[${index}].options[${childIndex}].topic_option_name`,
                child.topic_option_name
              );
              formData.append(
                `items[${index}].options[${childIndex}].topic_option_order`,
                childIndex + 1
              );
              formData.append(
                `items[${index}].options[${childIndex}].is_answer`,
                child.is_answer
              );
            });
            if (_.has(item, "topic_item_id")) {
              let oldItems = currentData.items.find(
                (current) => current.topic_item_id === item.topic_item_id
              );
              if (!_.isEmpty(oldItems)) {
                oldItems.options.map((oldItem, oldIndex) => {
                  if (
                    item.options.filter(
                      (itemOption) =>
                        itemOption.topic_option_id === oldItem.topic_option_id
                    ).length === 0
                  ) {
                    formData.append(
                      `items[${i}].remove_options`,
                      oldItem.topic_option_id
                    );
                    i += 1;
                  }
                });
              }
            }
          }
        });

        let i = 0;
        if (_.isArray(currentData.items)) {
          currentData.items.map((item) => {
            if (
              items.filter(
                (child) => child.topic_item_id === item.topic_item_id
              ).length === 0
            ) {
              formData.append(`remove_items[${i}]`, item.topic_item_id);
              i += 1;
            }
          });
        }
      } else {
        if (!currentTopicType.have_select) {
          formData.append("topic_answer", value.topic_answer);
        } else {
          options.map((child, childIndex) => {
            if (_.has(child, "topic_option_id")) {
              formData.append(
                `options[${childIndex}].topic_option_id`,
                child.topic_option_id
              );
            }
            if (currentTopicType.have_option_file) {
              if (_.has(optionFile[childIndex][0], "originFileObj")) {
                formData.append(
                  `options[${childIndex}].img`,
                  optionFile[childIndex][0].originFileObj
                );
              }
              formData.append(`options[${childIndex}].is_have_img`, true);
            } else {
              formData.append(
                `options[${childIndex}].topic_option_name`,
                child.topic_option_name
              );
            }
            formData.append(
              `options[${childIndex}].is_answer`,
              child.is_answer
            );
            formData.append(
              `options[${childIndex}].topic_option_order`,
              childIndex + 1
            );
          });
          let i = 0;
          currentData.options.map((item) => {
            if (
              options.filter(
                (child) => child.topic_option_id === item.topic_option_id
              ).length === 0
            ) {
              formData.append(`remove_options[${i}]`, item.topic_option_id);
              i += 1;
            }
          });
        }
      }

      handleUpdateTopic(formData, setIsLoading, callback, getData);
    } else {
      showMessage({ type: "warning", content: errorMessage });
    }
  };
  const handleFinish = (value) => {
    if (mode === "create") {
      handleCreate(value);
    } else if (mode === "import") {
      handleImport(value);
    } else {
      handleUpdate(value);
    }
  };

  const handleFormChange = (change, all) => {
    if (_.has(change, "topic_type_id")) {
      if (change.topic_type_id !== undefined) {
        props.topicTypeList.map((item) => {
          if (item.topic_type_id === change.topic_type_id) {
            setCurrentTopicType(item);
          }
        });
      } else {
        setCurrentTopicType({});
      }
      setItems([]);
      setItemImage([]);
      setItemAudio([]);
    }
    setFormValue({
      ...formValue,
      ...all,
    });
  };

  const renderDrawerView = () => {
    if (mode === "view") {
      return drawerVisible && renderViewDrawer();
    } else if (mode === "import") {
      return drawerVisible && renderImportDrawer();
    } else {
      return drawerVisible && renderFormDrawer();
    }
  };

  const renderViewDrawer = () => {
    return (
      <div style={{ paddingTop: "30px" }}>
        <span
          style={{
            color: props.color.textColor,
            fontSize: "18px",
            borderBottom: "2px solid #13D1AC",
          }}
        >
          NO.{currentData.topic_id} {currentData.topic_type.topic_type_name}
        </span>

        {
          <div
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: props.color.inputTitleColor,
              marginTop: "16px",
            }}
          >
            {props.i18n.topic_manager_title}
          </div>
        }
        {currentData.topic_title !== null && currentData.topic_title !== "" && (
          <div
            style={{
              fontSize: "18px",
              color: props.color.textColor,
            }}
            dangerouslySetInnerHTML={{ __html: currentData.topic_title }}
          ></div>
        )}
        {currentData.topic_group !== null &&
          currentData.topic_group !== undefined && (
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: props.color.inputTitleColor,
                marginTop: "16px",
              }}
            >
              {props.i18n.topic_group_name}
            </div>
          )}
        {currentData.topic_group !== null &&
          currentData.topic_group !== undefined && (
            <div
              style={{
                fontSize: "16px",
                color: props.color.textColor,
                marginTop: "16px",
              }}
            >
              {currentData.topic_group.topic_group_name}
            </div>
          )}
        {currentData.topic_group !== null &&
          currentData.topic_group !== undefined && (
            <div
              style={{
                fontSize: "14px",
                color: props.color.textColor,
              }}
              dangerouslySetInnerHTML={{
                __html: currentData.topic_group.topic_group_desc,
              }}
            ></div>
          )}

        {currentData.imgs !== null &&
          currentData.imgs.map((item) => {
            return (
              <img
                src={item.file_url}
                alt="img"
                style={{
                  width: "auto",
                  maxWidth: "100%",
                  height: "auto",
                  marginBottom: "12px",
                }}
              />
            );
          })}
        {currentData.audio !== null &&
          currentData.audio.map((item) => {
            return (
              <ReactPlayer
                controls
                url={item.file_url}
                style={{ marginBottom: "12px" }}
                height="54px"
                width="300px"
              />
            );
          })}
        {currentData.options !== null &&
          currentData.options.map((child, childIndex) => {
            return (
              <div
                style={{
                  fontSize: "14px",
                  color: props.color.textColor,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {child.is_answer ? (
                  <img
                    src={Images.tick}
                    alt="tick"
                    style={{
                      width: "17px",
                      height: "13px",
                      marginRight: "16px",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "17px",
                      height: "13px",
                      marginRight: "16px",
                    }}
                  />
                )}
                {optionList[childIndex]}. {child.topic_option_name}
                {child.topic_option_img !== null && (
                  <ReactPlayer
                    height="54px"
                    width="300px"
                    controls
                    url={child.topic_option_img}
                    style={{ marginBottom: "12px" }}
                  />
                )}
                {child.topic_option_audio !== null && (
                  <ReactPlayer
                    height="54px"
                    width="300px"
                    controls
                    url={child.topic_option_audio}
                    style={{ marginBottom: "12px" }}
                  />
                )}
              </div>
            );
          })}
        {currentData.topic_answer !== "" &&
          currentData.topic_answer !== null &&
          (currentData.options === null ||
            currentData.options.length === 0) && (
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: props.color.inputTitleColor,
              }}
            >
              {props.i18n.topic_manager_answer}
            </div>
          )}
        {currentData.topic_answer !== "" &&
          currentData.topic_answer !== null &&
          (currentData.options === null ||
            currentData.options.length === 0) && (
            <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: props.color.inputTitleColor,
                marginBottom: "16px",
              }}
            >
              {currentData.topic_answer}
            </div>
          )}
        {currentData.topic_detail !== "" &&
          currentData.topic_detail !== null && (
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: props.color.inputTitleColor,
              }}
            >
              {props.i18n.topic_manager_answer_desc}
            </div>
          )}
        <div
          style={{
            fontSize: "14px",
            color: props.color.textColor,
          }}
          dangerouslySetInnerHTML={{ __html: currentData.topic_detail }}
        ></div>
        <div
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: props.color.inputTitleColor,
          }}
        >
          {props.i18n.tag}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "16px",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {currentData.tags.map((item) => {
            return (
              <div
                style={{
                  height: "40px",
                  lineHeight: "40px",
                  padding: "0px 12px",
                  backgroundColor: "#FCF1DF",
                  color: "#242933",
                  marginRight: "16px",
                  borderRadius: "15px",
                  textAlign: "center",
                }}
              >
                {item.tag_name}
              </div>
            );
          })}
        </div>
        <div
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: props.color.inputTitleColor,
            marginTop: "30px",
          }}
        >
          {props.i18n.ability}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "16px",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {currentData.abilities.map((item) => {
            return (
              <div
                style={{
                  height: "40px",
                  lineHeight: "40px",
                  padding: "0px 12px",
                  backgroundColor: "#FCF1DF",
                  color: "#242933",
                  marginRight: "16px",
                  borderRadius: "15px",
                  textAlign: "center",
                }}
              >
                {item.ability_name}
              </div>
            );
          })}
        </div>

        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "#159E90",
            marginRight: "12px",
            marginBottom: "16px",
            marginTop: "16px",
          }}
        ></div>
        {currentData.items !== null && currentData.items.length > 0 && (
          <div
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginTop: "16px",
              color: props.color.inputTitleColor,
            }}
          >
            {props.i18n.topic_manager_topic_list}
          </div>
        )}
        {currentData.items !== null &&
          currentData.items.length > 0 &&
          currentData.items.map((item, index) => {
            return (
              <div>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      fontSize: "14px",
                      color: props.color.textColor,
                      marginRight: "4px",
                    }}
                  >
                    {index + 1}.
                  </div>

                  {item.imgs !== null &&
                    item.imgs.map((child) => {
                      return (
                        <img
                          src={child.file_url}
                          alt="img"
                          style={{
                            width: "auto",
                            maxWidth: "100%",
                            height: "auto",
                            marginBottom: "16px",
                          }}
                        />
                      );
                    })}
                  {item.audio !== null &&
                    item.audio.map((child) => {
                      return (
                        <ReactPlayer
                          controls
                          url={child.file_url}
                          style={{ marginBottom: "16px" }}
                        />
                      );
                    })}
                  {item.topic_item_title !== "" && (
                    <div
                      style={{
                        fontSize: "14px",
                        color: props.color.textColor,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item.topic_item_title,
                      }}
                    ></div>
                  )}
                </div>
                {item.options !== null &&
                  item.options.map((child, childIndex) => {
                    return (
                      <div
                        style={{
                          fontSize: "14px",
                          color: props.color.textColor,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {child.is_answer ? (
                          <img
                            src={Images.tick}
                            alt="tick"
                            style={{
                              width: "17px",
                              height: "13px",
                              marginRight: "16px",
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              width: "17px",
                              height: "13px",
                              marginRight: "16px",
                            }}
                          />
                        )}
                        {optionList[childIndex]}. {child.topic_option_name}
                      </div>
                    );
                  })}
                {(item.options === null || item.options.length === 0) && (
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: props.color.inputTitleColor,
                    }}
                  >
                    {props.i18n.topic_manager_answer}
                  </div>
                )}
                {(item.options === null || item.options.length === 0) && (
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: props.color.inputTitleColor,
                      marginBottom: "16px",
                    }}
                  >
                    {item.topic_item_answer}
                  </div>
                )}
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: props.color.inputTitleColor,
                  }}
                >
                  {props.i18n.topic_manager_answer_desc}
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: props.color.textColor,
                  }}
                  dangerouslySetInnerHTML={{ __html: item.topic_item_detail }}
                ></div>
              </div>
            );
          })}
      </div>
    );
  };

  const handleChangeContent = (value) => {
    setHtmlText(value.toHTML());
  };

  const handleChangeItemsContent = (e, index) => {
    // 子題目 題目內容設定

    let temp = JSON.parse(JSON.stringify(items));
    temp[index].topic_item_title = e.toHTML();
    setItems(temp);
  };

  const handleChangeItemsImage = ({ fileList: newFileList }, key, index) => {
    // 子題目 題目內容設定
    let temp = [];
    if (key === "img") {
      let temp = JSON.parse(JSON.stringify(itemImage));
      itemImage.map((item) => {
        temp.push(item);
      });
      if (newFileList.length > 1) {
        temp[index] = newFileList.splice(1, 1);
      } else {
        temp[index] = newFileList;
      }
      setItemImage(temp);
    } else {
      itemAudio.map((item) => {
        temp.push(item);
      });

      if (newFileList.length > 1) {
        temp[index] = newFileList.splice(1, 1);
      } else {
        temp[index] = newFileList;
      }

      setItemAudio(temp);
    }
  };

  const handleChangeItemsOptionCheckbox = (checked, index, itemIndex) => {
    // 子題目 選項答案 checkbox

    let temp = JSON.parse(JSON.stringify(items));
    let tempOption = temp[itemIndex].options;
    tempOption.map((item, childIndex) => {
      if (index === childIndex) {
        tempOption[childIndex].is_answer = checked;
      } else {
        tempOption[childIndex].is_answer = false;
      }
    });
    temp[itemIndex].options = tempOption;
    setItems(temp);
  };

  const handleChangeItemsOptionName = (value, index, itemIndex) => {
    // 子題目 更改選項名稱
    let temp = JSON.parse(JSON.stringify(items));
    let tempOption = temp[itemIndex].options;
    tempOption[index].topic_option_name = value;
    temp[itemIndex].options = tempOption;
    setItems(temp);
  };

  const handleItemsOptionsSort = (index, key, itemIndex) => {
    // 子題目 選項排序

    let temp = JSON.parse(JSON.stringify(items));
    let tempOption = temp[itemIndex].options;
    let tempA;
    tempA = tempOption[index];
    tempOption[index] = tempOption[index + key];
    tempOption[index + key] = tempA;
    temp[itemIndex].options = tempOption;
    setItems(temp);
  };

  const handleRemoveItemsOptions = (index, itemIndex) => {
    // 子題目 移除選項
    let temp = JSON.parse(JSON.stringify(items));
    let tempOption = temp[itemIndex].options;
    tempOption = tempOption.filter((item, childIndex) => childIndex !== index);
    temp[itemIndex].options = tempOption;
    setItems(temp);
  };
  const handleAddItemOptions = (itemIndex) => {
    // 子題目 新增選項
    let temp = JSON.parse(JSON.stringify(items));
    temp[itemIndex].options.push({
      topic_option_name: "",
      is_answer: false,
    });
    setItems(temp);
  };

  const handleUpdateItemsAnswer = (value, itemIndex) => {
    // 子題目 答案

    let temp = JSON.parse(JSON.stringify(items));
    temp[itemIndex].topic_item_answer = value;
    setItems(temp);
  };

  const handleUpdateItemsDetail = (value, itemIndex) => {
    // 子題目 題目解析

    let temp = JSON.parse(JSON.stringify(items));
    temp[itemIndex].topic_item_detail = value.toHTML();
    setItems(temp);
  };

  const handleDeleteItems = (itemIndex) => {
    // 子題目 題目解析
    let temp = JSON.parse(JSON.stringify(items));
    temp = temp.filter((item, index) => index !== itemIndex);
    let tempAudio = itemAudio.filter((item, index) => index !== itemIndex);
    let tempImage = itemImage.filter((item, index) => index !== itemIndex);
    setItems(temp);
    setItemAudio(tempAudio);
    setItemImage(tempImage);
  };

  const handleCreateFormTopicGroup = () => {
    let payload = {
      type: 5,
      topic_group_name: formValue.topic_group_name,
      topic_group_desc: null,
      tags: [],
      abilities: [],
    };
    const callback = () => {
      setShowCreateGroup(false);
    };
    props.handleCreateFormTopicGroup(payload, setIsLoading, callback);
  };

  const handleAddTags = (value, dataCallback) => {
    const { handleCreateTag, saveTagList } = props;
    let payload = {
      tag_name: value,
      tag_type_id: 6,
    };
    const callback = (data) => {
      saveTagList(data);
      dataCallback();

      let temp = [];
      if (formValue.tags === undefined) {
        temp = [data.tag_id];
      } else {
        temp = [...formValue.tags, data.tag_id];
      }
      createForm.current.setFieldsValue({ tags: temp });
      setFormValue({
        ...formValue,
        tags: temp,
      });
    };
    handleCreateTag(payload, setIsLoading, callback);
  };

  const renderImportDrawer = () => (
    <Form
      {...layout}
      ref={createForm}
      name="basic"
      initialValues={{
        ...formValue,
      }}
      style={{ marginTop: "16px" }}
      onValuesChange={handleFormChange}
      onFinish={handleFinish}
    >
      <Row
        gutter={24}
        className="scrollList"
        style={{
          maxHeight: `${props.screenHeight - 164}px`,
          marginRight: "0px",
          paddingBottom: "8px",
        }}
      >
        <Col span={24}>
          <FormInputSelect
            name="topic_type_id"
            type="text"
            required
            options={props.topicTypeList}
            allowClear={true}
            title={props.i18n.topic_manager_topic_type}
            textColor={props.color.inputTitleColor}
            placeholder={`${props.i18n.placeholder}${props.i18n.topic_manager_topic_type}`}
          />
        </Col>
        <Col span={24}>
          <Form.Item
            name="topic"
            valuePropName="topic"
            rules={[
              {
                required: true,
                message: `${props.i18n.placeholder}${props.i18n.topic_manager_import_topic}`,
              },
            ]}
            label={
              <div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: "18px",
                  padding: "0px",
                  fontWeight: "bold",
                }}
              >
                {props.i18n.topic_manager_import_topic}
              </div>
            }
            getValueFromEvent={normFile}
          >
            <Upload
              accept=".xlsx"
              // fileList={topicData}
              // onChange={handleTopicFileChange}
              onPreview={handlePreview}
              customRequest={dummyRequest}
              beforeUpload={(file) => {
                const isJPG =
                  file.type === "image/jpeg" || file.type === "image/png";
                if (!isJPG) {
                  return false;
                } else {
                  return true;
                }
              }}
            >
              <div>
                <Button
                  style={{
                    height: "40px",
                    backgroundColor: "#4A9E8F",
                    borderRadius: "20px",
                    color: "white",
                    border:
                      props.color.currentTheme === "dark"
                        ? "none"
                        : "2px solid #C7E3D4",
                    marginRight: "30px",
                  }}
                >
                  <div style={{ color: "white" }}>{props.i18n.uploadFile}</div>
                </Button>
              </div>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="files"
            valuePropName="files"
            label={
              <div
                style={{
                  color: props.color.inputTitleColor,
                  fontSize: "18px",
                  padding: "0px",
                  fontWeight: "bold",
                }}
              >
                {props.i18n.topic_manager_import_file}
              </div>
            }
            getValueFromEvent={normFile}
          >
            <Upload
              accept=".mp3,.mp4"
              // fileList={fileData}
              multiple={true}
              // onChange={handleFileChange}
              onPreview={handlePreview}
              customRequest={dummyRequest}
              beforeUpload={(file) => {
                const isJPG =
                  file.type === "image/jpeg" || file.type === "image/png";
                if (!isJPG) {
                  return false;
                } else {
                  return true;
                }
              }}
            >
              <div>
                <Button
                  style={{
                    height: "40px",
                    backgroundColor: "#4A9E8F",
                    borderRadius: "20px",
                    color: "white",
                    border:
                      props.color.currentTheme === "dark"
                        ? "none"
                        : "2px solid #C7E3D4",
                    marginRight: "30px",
                  }}
                >
                  <div style={{ color: "white" }}>{props.i18n.uploadAudio}</div>
                </Button>
              </div>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item style={{ width: "100%", textAlign: "center" }}>
        <Button
          loading={isLoading}
          htmlType="submit"
          style={{
            height: "50px",
            backgroundColor: "#4A9E8F",
            width: "128px",
            borderRadius: "20px",
            color: "white",
            border:
              props.color.currentTheme === "dark"
                ? "none"
                : "2px solid #C7E3D4",
            marginRight: "30px",
          }}
        >
          {props.i18n.swal_yes}
        </Button>
        <Button
          loading={isLoading}
          onClick={() => setDrawerVisible(false)}
          style={{
            height: "50px",
            backgroundColor: "#D53838",
            width: "128px",
            borderRadius: "20px",
            color: "white",
            border:
              props.color.currentTheme === "dark"
                ? "none"
                : "2px solid #C7E3D4",
          }}
        >
          {props.i18n.swal_no}
        </Button>
      </Form.Item>
    </Form>
  );

  const renderFormDrawer = () => {
    return (
      <Form
        {...layout}
        ref={createForm}
        name="basic"
        initialValues={{
          ...formValue,
        }}
        style={{ marginTop: "16px" }}
        onValuesChange={handleFormChange}
        onFinish={handleFinish}
      >
        <Row
          gutter={24}
          className="scrollList"
          style={{
            maxHeight: `${props.screenHeight - 164}px`,
            marginRight: "0px",
            paddingBottom: "8px",
          }}
        >
          <Col span={24}>
            <FormInputSelect
              name="topic_type_id"
              type="text"
              required
              options={props.topicTypeList}
              allowClear={true}
              title={props.i18n.topic_manager_topic_type}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.topic_manager_topic_type}`}
            />
          </Col>
          <Col span={24} style={{ display: "flex", alignItems: "flex-end" }}>
            <FormInputSelect
              name="topic_group_id"
              type="text"
              options={props.topicGroupFormList}
              allowClear={true}
              title={props.i18n.topic_manager_topic_group}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.topic_manager_topic_group}`}
            />

            <Button
              style={{
                height: "50px",
                minWidth: "128px",
                backgroundColor: "#4A9E8F",
                width: "128px",
                borderRadius: "20px",
                color: "white",
                marginBottom: "24px",
                border:
                  props.color.currentTheme === "dark"
                    ? "none"
                    : "2px solid #C7E3D4",
                marginLeft: "30px",
              }}
              onClick={() => setShowCreateGroup(true)}
            >
              {props.i18n.create_btn}
            </Button>
          </Col>
          {showCreateGroup && (
            <Col
              span={24}
              style={{ display: "flex", alignItems: "flex-start" }}
            >
              <FormInput
                name="topic_group_name"
                textColor={props.color.inputTitleColor}
                placeholder={`${props.i18n.placeholder}${props.i18n.topic_group_name}`}
              />
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  minWidth: "40px",
                  borderRadius: "15px",
                  border: "1px solid #C1EFD6",
                  backgroundColor:
                    formValue.topic_group_name === "" ||
                    formValue.topic_group_name === undefined
                      ? "#707070"
                      : "#42C1B4",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor:
                    formValue.topic_group_name === "" ||
                    formValue.topic_group_name === undefined
                      ? "not-allowed"
                      : "pointer",
                  marginTop: "5px",
                  marginLeft: "48px",
                }}
                onClick={
                  formValue.topic_group_name === "" ||
                  formValue.topic_group_name === undefined
                    ? () => {}
                    : () => handleCreateFormTopicGroup()
                }
                disabled={
                  formValue.topic_group_name === "" ||
                  formValue.topic_group_name === undefined
                }
              >
                <img
                  alt="check"
                  src={Images.check_icon}
                  style={{ width: "20px", height: "13px" }}
                />
              </div>
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  minWidth: "40px",
                  borderRadius: "15px",
                  border: "1px solid #F31616",
                  backgroundColor: "#D53838",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  marginTop: "5px",
                  marginLeft: "16px",
                  marginRight: "16px",
                }}
                onClick={() => {
                  setShowCreateGroup(false);
                }}
              >
                <img
                  alt="cancel"
                  src={Images.cancel_icon}
                  style={{ width: "20px", height: "13px" }}
                />
              </div>
            </Col>
          )}
          {currentTopicType.have_title && (
            <Col span={24}>
              <HtmlEditor
                title={props.i18n.topic_manager_title}
                i18nMode={props.i18nMode}
                required
                propName="topic_title"
                value={formValue.topic_title}
                textColor={props.color.inputTitleColor}
                onEditorStateChange={handleChangeContent}
              />
            </Col>
          )}
          {currentTopicType.have_img && (
            <Col span={24}>
              <Form.Item
                valuePropName="fileList"
                rules={[
                  {
                    required: true,
                    message: `${props.i18n.placeholder}${props.i18n.img}`,
                  },
                ]}
                label={
                  <div
                    style={{
                      color: props.color.inputTitleColor,
                      fontSize: "18px",
                      padding: "0px",
                      fontWeight: "bold",
                    }}
                  >
                    {props.i18n.img}
                  </div>
                }
                getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture-card"
                  accept=".PNG,.JPG,.JPEG"
                  fileList={imageData}
                  onChange={(e) => handleImageChange(e, "img")}
                  onPreview={handlePreview}
                  customRequest={dummyRequest}
                  beforeUpload={(file) => {
                    const isJPG =
                      file.type === "image/jpeg" || file.type === "image/png";
                    if (!isJPG) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8, color: "#292C33" }}>
                      {props.i18n.upload}
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </Col>
          )}
          {currentTopicType.have_audio && (
            <Col span={24}>
              <Form.Item
                valuePropName="fileList"
                rules={[
                  {
                    required: true,
                    message: `${props.i18n.placeholder}${props.i18n.audio}`,
                  },
                ]}
                label={
                  <div
                    style={{
                      color: props.color.inputTitleColor,
                      fontSize: "18px",
                      padding: "0px",
                      fontWeight: "bold",
                    }}
                  >
                    {props.i18n.audio}
                  </div>
                }
                getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture-card"
                  accept="audio/*"
                  fileList={audioData}
                  onChange={(e) => handleImageChange(e, "audio")}
                  customRequest={dummyRequest}
                  onPreview={handlePreview}
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8, color: "#292C33" }}>
                      {props.i18n.uploadAudio}
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <FormDropdownSelect
              name="tags"
              style={{ width: "100% !important" }}
              mode="multiple"
              type="array"
              options={props.formTagList}
              allowClear={true}
              title={props.i18n.character_tag}
              textColor={props.color.inputTitleColor}
              handleAddItem={handleAddTags}
              placeholder={`${props.i18n.placeholder}${props.i18n.character_tag}`}
            />
          </Col>
          <Col span={24}>
            <FormInputSelect
              name="abilities"
              mode="multiple"
              type="array"
              options={abilityList}
              allowClear={true}
              title={props.i18n.character_ability}
              textColor={props.color.inputTitleColor}
              placeholder={`${props.i18n.placeholder}${props.i18n.character_ability}`}
            />
          </Col>
          {!currentTopicType.have_item && currentTopicType.have_select && (
            <Col span={24}>
              <div
                style={{
                  width: "100%",
                  backgroundColor:
                    props.color.currentTheme === "dark" ? "#002C51" : "#C2EBE8",
                  borderRadius: "20px",
                  height: "70px",
                  padding: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "16px",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    color: props.color.textColor,
                    minWidth: "60px",
                    textAlign: "left",
                  }}
                >
                  {props.i18n.answer}
                </div>
                {!currentTopicType.have_option_file && (
                  <div
                    style={{
                      fontSize: "18px",
                      color: props.color.textColor,
                      width: "100%",
                    }}
                  >
                    {props.i18n.option_content}
                  </div>
                )}
                {currentTopicType.have_option_file && (
                  <div
                    style={{
                      fontSize: "18px",
                      color: props.color.textColor,
                      width: "100%",
                    }}
                  >
                    {props.i18n.option_file}
                  </div>
                )}
                <div
                  style={{
                    fontSize: "18px",
                    color: props.color.textColor,
                    minWidth: "60px",
                    textAlign: "center",
                  }}
                >
                  {props.i18n.operation}
                </div>
              </div>
              {options.map((item, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      padding: "0px 16px 0px 8px",
                      marginBottom: "16px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        minWidth: "60px",
                        marginRight: "16px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Checkbox
                        checked={item.is_answer}
                        onChange={(e) =>
                          handleChangeOptionCheckbox(e.target.checked, index)
                        }
                        className={
                          props.color.currentTheme === "dark"
                            ? "dark-checkbox"
                            : "light-checkbox"
                        }
                      />
                    </div>
                    {!currentTopicType.have_option_file && (
                      <CommonInput
                        style={{ width: "100% !important" }}
                        value={item.topic_option_name}
                        onChange={(e) =>
                          handleChangeOptionName(e.target.value, index)
                        }
                        textColor={props.color.inputTitleColor}
                        placeholder={`${props.i18n.placeholder}${props.i18n.option_content}`}
                      />
                    )}
                    {currentTopicType.have_option_file && (
                      <div style={{ width: "100%" }}>
                        <Upload
                          accept=".mp3,.mp4,.mov"
                          fileList={item.img}
                          onChange={(e) => handleChangeOptionFile(e, index)}
                          onPreview={handlePreview}
                          customRequest={dummyRequest}
                          beforeUpload={(file) => {
                            const isJPG =
                              file.type === "image/jpeg" ||
                              file.type === "image/png";
                            if (!isJPG) {
                              return false;
                            } else {
                              return true;
                            }
                          }}
                        >
                          <Button
                            style={{
                              height: "50px",
                              backgroundColor: "#4A9E8F",
                              width: "128px",
                              borderRadius: "20px",
                              color: "white",
                              border:
                                props.color.currentTheme === "dark"
                                  ? "none"
                                  : "2px solid #C7E3D4",
                              marginRight: "30px",
                            }}
                            type="primary"
                          >
                            {props.i18n.uploadAudio}
                          </Button>
                        </Upload>
                      </div>
                    )}
                    <div
                      style={{
                        minWidth: "60px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        marginRight: "24px",
                        marginLeft: "16px",
                      }}
                    >
                      <div
                        style={{
                          width: "12px",
                          lineHeight: "12px",
                          marginLeft: "8px",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                      >
                        <img
                          src={
                            props.color.currentTheme === "dark"
                              ? Images.up_dark
                              : Images.up_light
                          }
                          alt="sort_up"
                          style={{
                            cursor:
                              (index === options.length - 1 && index === 0) ||
                              index === 0
                                ? "not-allowed"
                                : "pointer",
                          }}
                          disabled={
                            (index === options.length - 1 && index === 0) ||
                            index === 0
                          }
                          onClick={
                            (index === options.length - 1 && index === 0) ||
                            index === 0
                              ? () => {}
                              : () => handleOptionsSort(index, -1)
                          }
                        />
                        <img
                          src={
                            props.color.currentTheme === "dark"
                              ? Images.down_dark
                              : Images.down_light
                          }
                          alt="sort_down"
                          disabled={options.length - 1 === index}
                          style={{
                            cursor:
                              options.length - 1 === index
                                ? "not-allowed"
                                : "pointer",
                          }}
                          onClick={
                            options.length - 1 === index
                              ? () => {}
                              : () => handleOptionsSort(index, 1)
                          }
                        />
                      </div>
                      <Button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "15px",
                          width: "40px",
                          height: "40px",
                          backgroundColor: "#D53838",
                          border: "1px solid #F31616",
                          marginLeft: "8px",
                        }}
                        onClick={() => handleRemoveOptions(index)}
                      >
                        <img
                          src={Images.delete}
                          style={{ width: "22px", height: "14px" }}
                          alt={props.i18n.delete}
                        />
                      </Button>
                    </div>
                  </div>
                );
              })}
              <Button
                type="dashed"
                onClick={() =>
                  setOptions([
                    ...options,
                    { topic_option_name: "", is_answer: false },
                  ])
                }
                block
                style={{
                  fontSize: "18px",
                  border: "2px dashed #0EA285",
                  height: "50px",
                  lineHeight: "50px",
                  padding: "0px",
                  color:
                    props.color.currentTheme === "dark" ? "#00FFCE" : "#26695C",
                  backgroundColor: "transparent",
                  borderRadius: "15px",
                  marginBottom: "30px",
                }}
              >
                + {props.i18n.create_btn}
              </Button>
            </Col>
          )}
          {currentTopicType.have_item && (
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "#159E90",
                marginRight: "12px",
                marginBottom: "24px",
              }}
            ></div>
          )}
          {currentTopicType.have_item && (
            <Col
              span={24}
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: props.color.inputTitleColor,
                marginBottom: "12px",
                marginLeft: "16px",
              }}
            >
              <div style={{ marginLeft: "0px" }}>
                {props.i18n.topic_manager_topic_list}
              </div>
            </Col>
          )}
          {/* 子題目新增 */}
          {items.map((item, itemIndex) => {
            return (
              <Row style={{ paddingLeft: "16px", paddingRight: "12px" }}>
                {
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      zIndex: "1",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "22px",
                        color: props.color.inputTitleColor,
                        marginLeft: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      第{itemIndex + 1}題
                    </div>
                    <Button
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#D53838",
                        border: `1px solid #F31616`,
                        borderRadius: "15px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteItems(itemIndex)}
                    >
                      <img
                        alt="delete"
                        src={Images.delete}
                        style={{ width: "19.4px", height: "19.4px" }}
                      />
                    </Button>
                  </div>
                }
                {currentTopicType.have_title && (
                  <Col span={24}>
                    <HtmlEditor
                      title={props.i18n.topic_manager_title}
                      i18nMode={props.i18nMode}
                      required
                      value={item.topic_item_title}
                      textColor={props.color.inputTitleColor}
                      onEditorStateChange={(e) =>
                        handleChangeItemsContent(e, itemIndex)
                      }
                    />
                  </Col>
                )}
                {currentTopicType.have_img && (
                  <Col span={12}>
                    <Form.Item
                      valuePropName="fileList"
                      rules={[
                        {
                          required: true,
                          message: `${props.i18n.placeholder}${props.i18n.img}`,
                        },
                      ]}
                      label={
                        <div
                          style={{
                            color: props.color.inputTitleColor,
                            fontSize: "18px",
                            padding: "0px",
                            fontWeight: "bold",
                          }}
                        >
                          {props.i18n.img}
                        </div>
                      }
                      getValueFromEvent={normFile}
                    >
                      <Upload
                        listType="picture-card"
                        accept=".PNG,.JPG,.JPEG"
                        fileList={itemImage[itemIndex]}
                        onChange={(e) =>
                          handleChangeItemsImage(e, "img", itemIndex)
                        }
                        onPreview={handlePreview}
                        customRequest={dummyRequest}
                        beforeUpload={(file) => {
                          const isJPG =
                            file.type === "image/jpeg" ||
                            file.type === "image/png";
                          if (!isJPG) {
                            return false;
                          } else {
                            return true;
                          }
                        }}
                      >
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8, color: "#292C33" }}>
                            {props.i18n.upload}
                          </div>
                        </div>
                      </Upload>
                    </Form.Item>
                  </Col>
                )}
                {currentTopicType.have_audio && (
                  <Col span={12}>
                    <Form.Item
                      valuePropName="fileList"
                      rules={[
                        {
                          required: true,
                          message: `${props.i18n.placeholder}${props.i18n.audio}`,
                        },
                      ]}
                      label={
                        <div
                          style={{
                            color: props.color.inputTitleColor,
                            fontSize: "18px",
                            padding: "0px",
                            fontWeight: "bold",
                          }}
                        >
                          {props.i18n.audio}
                        </div>
                      }
                      getValueFromEvent={normFile}
                    >
                      <Upload
                        listType="picture-card"
                        accept="audio/*"
                        fileList={itemAudio[itemIndex]}
                        onChange={(e) => {
                          handleChangeItemsImage(e, "audio", itemIndex);
                        }}
                        customRequest={dummyRequest}
                        onPreview={handlePreview}
                      >
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8, color: "#292C33" }}>
                            {props.i18n.uploadAudio}
                          </div>
                        </div>
                      </Upload>
                    </Form.Item>
                  </Col>
                )}
                {currentTopicType.have_select && (
                  <Col span={24}>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor:
                          props.color.currentTheme === "dark"
                            ? "#002C51"
                            : "#C2EBE8",
                        borderRadius: "20px",
                        height: "70px",
                        padding: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "16px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "18px",
                          color: props.color.textColor,
                          minWidth: "60px",
                          textAlign: "left",
                        }}
                      >
                        {props.i18n.answer}
                      </div>
                      {!currentTopicType.have_option_file && (
                        <div
                          style={{
                            fontSize: "18px",
                            color: props.color.textColor,
                            width: "100%",
                          }}
                        >
                          {props.i18n.option_content}
                        </div>
                      )}
                      {currentTopicType.have_option_file && (
                        <div
                          style={{
                            fontSize: "18px",
                            color: props.color.textColor,
                            width: "100%",
                          }}
                        >
                          {props.i18n.option_file}
                        </div>
                      )}
                      <div
                        style={{
                          fontSize: "18px",
                          color: props.color.textColor,
                          minWidth: "60px",
                          textAlign: "center",
                        }}
                      >
                        {props.i18n.operation}
                      </div>
                    </div>
                    {item.options.map((child, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            padding: "0px 16px 0px 8px",
                            marginBottom: "16px",
                          }}
                        >
                          <div
                            style={{
                              minWidth: "60px",
                              marginRight: "16px",
                              height: "50px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Checkbox
                              checked={child.is_answer}
                              onChange={(e) =>
                                handleChangeItemsOptionCheckbox(
                                  e.target.checked,
                                  index,
                                  itemIndex
                                )
                              }
                              className={
                                props.color.currentTheme === "dark"
                                  ? "dark-checkbox"
                                  : "light-checkbox"
                              }
                            />
                          </div>
                          {!currentTopicType.have_option_file && (
                            <CommonInput
                              style={{ width: "100% !important" }}
                              value={child.topic_option_name}
                              onChange={(e) =>
                                handleChangeItemsOptionName(
                                  e.target.value,
                                  index,
                                  itemIndex
                                )
                              }
                              textColor={props.color.inputTitleColor}
                              placeholder={`${props.i18n.placeholder}${props.i18n.option_content}`}
                            />
                          )}
                          <div
                            style={{
                              minWidth: "60px",
                              height: "50px",
                              display: "flex",
                              alignItems: "center",
                              marginRight: "24px",
                              marginLeft: "16px",
                            }}
                          >
                            <div
                              style={{
                                width: "12px",
                                lineHeight: "12px",
                                marginLeft: "8px",
                                cursor: "pointer",
                                userSelect: "none",
                              }}
                            >
                              <img
                                src={
                                  props.color.currentTheme === "dark"
                                    ? Images.up_dark
                                    : Images.up_light
                                }
                                alt="sort_up"
                                style={{
                                  cursor:
                                    (index === options.length - 1 &&
                                      index === 0) ||
                                    index === 0
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                                onClick={
                                  (index === options.length - 1 &&
                                    index === 0) ||
                                  index === 0
                                    ? () => {}
                                    : () =>
                                        handleItemsOptionsSort(
                                          index,
                                          -1,
                                          itemIndex
                                        )
                                }
                              />
                              <img
                                src={
                                  props.color.currentTheme === "dark"
                                    ? Images.down_dark
                                    : Images.down_light
                                }
                                alt="sort_down"
                                style={{
                                  cursor:
                                    options.length - 1 === index
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                                onClick={
                                  options.length - 1 === index
                                    ? () => {}
                                    : () =>
                                        handleItemsOptionsSort(
                                          index,
                                          1,
                                          itemIndex
                                        )
                                }
                              />
                            </div>
                            <Button
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "15px",
                                width: "40px",
                                height: "40px",
                                backgroundColor: "#D53838",
                                border: "1px solid #F31616",
                                marginLeft: "8px",
                              }}
                              onClick={() =>
                                handleRemoveItemsOptions(index, itemIndex)
                              }
                            >
                              <img
                                src={Images.delete}
                                style={{ width: "22px", height: "14px" }}
                                alt={props.i18n.delete}
                              />
                            </Button>
                          </div>
                        </div>
                      );
                    })}
                    <Button
                      type="dashed"
                      onClick={() => handleAddItemOptions(itemIndex)}
                      block
                      style={{
                        fontSize: "18px",
                        border: "2px dashed #0EA285",
                        height: "50px",
                        lineHeight: "50px",
                        padding: "0px",
                        color:
                          props.color.currentTheme === "dark"
                            ? "#00FFCE"
                            : "#26695C",
                        backgroundColor: "transparent",
                        borderRadius: "15px",
                        marginBottom: "30px",
                        zIndex: 1,
                      }}
                    >
                      + {props.i18n.create_btn}
                    </Button>
                  </Col>
                )}
                {!currentTopicType.have_select && (
                  <Col span={24}>
                    <FormInput
                      name="topic_answer"
                      required
                      value={item.topic_item_answer}
                      onChange={(e) =>
                        handleUpdateItemsAnswer(e.target.value, itemIndex)
                      }
                      title={props.i18n.topic_manager_answer}
                      textColor={props.color.inputTitleColor}
                      placeholder={`${props.i18n.placeholder}${props.i18n.topic_manager_answer}`}
                    />
                  </Col>
                )}
                {
                  <Col span={24}>
                    <HtmlEditor
                      title={props.i18n.topic_manager_answer_desc}
                      i18nMode={props.i18nMode}
                      required
                      value={item.topic_item_detail}
                      textColor={props.color.inputTitleColor}
                      onEditorStateChange={(e) =>
                        handleUpdateItemsDetail(e, itemIndex)
                      }
                    />
                  </Col>
                }
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#159E90",
                    marginRight: "12px",
                    marginBottom: "24px",
                  }}
                ></div>
              </Row>
            );
          })}
          {currentTopicType.have_item && (
            <Col span={24}>
              <Button
                type="dashed"
                onClick={() => {
                  let tempList = [];

                  setItems([
                    ...items,
                    {
                      topic_item_title: "",
                      topic_item_answer: "",
                      topic_item_detail: "",
                      options: [],
                    },
                  ]);
                  setItemImage([...itemImage, []]);
                  // setItemAudio([...itemAudio, []]);
                  setItemAudio(itemAudio);
                }}
                block
                style={{
                  fontSize: "18px",
                  border: "2px dashed #0EA285",
                  height: "50px",
                  lineHeight: "50px",
                  padding: "0px",
                  color:
                    props.color.currentTheme === "dark" ? "#00FFCE" : "#26695C",
                  backgroundColor: "transparent",
                  borderRadius: "15px",
                  marginBottom: "30px",
                }}
              >
                + {props.i18n.create_topic}
              </Button>
            </Col>
          )}

          {!currentTopicType.have_item && !currentTopicType.have_select && (
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "#159E90",
                marginRight: "12px",
                marginBottom: "24px",
              }}
            ></div>
          )}
          {!currentTopicType.have_item && !currentTopicType.have_select && (
            <Col span={24}>
              <FormInput
                name="topic_answer"
                required
                title={props.i18n.topic_manager_answer}
                textColor={props.color.inputTitleColor}
                placeholder={`${props.i18n.placeholder}${props.i18n.topic_manager_answer}`}
              />
            </Col>
          )}
          {!currentTopicType.have_item && (
            <Col span={24}>
              <HtmlEditor
                title={props.i18n.topic_manager_answer_desc}
                i18nMode={props.i18nMode}
                required
                propName="topic_detail"
                value={formValue.topic_detail}
                textColor={props.color.inputTitleColor}
                onEditorStateChange={handleChangeContent}
              />
            </Col>
          )}
        </Row>
        <Form.Item style={{ width: "100%", textAlign: "center" }}>
          <Button
            loading={isLoading}
            htmlType="submit"
            style={{
              height: "50px",
              backgroundColor: "#4A9E8F",
              width: "128px",
              borderRadius: "20px",
              color: "white",
              border:
                props.color.currentTheme === "dark"
                  ? "none"
                  : "2px solid #C7E3D4",
              marginRight: "30px",
            }}
          >
            {props.i18n.swal_yes}
          </Button>
          <Button
            loading={isLoading}
            onClick={() => setDrawerVisible(false)}
            style={{
              height: "50px",
              backgroundColor: "#D53838",
              width: "128px",
              borderRadius: "20px",
              color: "white",
              border:
                props.color.currentTheme === "dark"
                  ? "none"
                  : "2px solid #C7E3D4",
            }}
          >
            {props.i18n.swal_no}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const handleDeleteTopic = (id) => {
    const { handleDeleteTopic, paging } = props;

    let getData = {
      search: search,
      type: topicType,
      tags: tag,
      abilities: ability,
      page: paging.page,
      pageCount: paging.pageCount,
    };

    Swal.fire({
      title: props.i18n.delete_confirm,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: props.i18n.swal_no,
      confirmButtonText: props.i18n.swal_yes,
      confirmButtonColor: "#42C1B4",
    }).then((result) => {
      if (result.value) {
        handleDeleteTopic(id, setIsLoading, () => {}, getData);
      }
    });
  };

  const handleSizeChange = (e) => {
    navigate(
      `/topic/manager?search=${search}${
        ability.length > 0 ? `&ability=${ability}` : ""
      }${topicType !== undefined ? `&topicType=${topicType}` : ""}${
        tag.length > 0 ? `&tag=${tag}` : ""
      }&page=1${e !== 12 ? `&pageCount=${e}` : ""}`
    );
  };

  const handlePageChange = (e) => {
    navigate(
      `/topic/manager?search=${search}${
        ability.length > 0 ? `&ability=${ability}` : ""
      }${topicType !== undefined ? `&topicType=${topicType}` : ""}${
        tag.length > 0 ? `&tag=${tag}` : ""
      }${
        props.paging.pageCount !== 12
          ? `&pageCount=${props.paging.pageCount}`
          : ""
      }${e !== 1 ? `&page=${e}` : ""}`
    );
  };

  const handleSearchChange = (e) => {
    setSearch(e);
    const getSearch = () => {
      navigate(
        `/topic/manager?search=${e}${
          ability.length > 0 ? `&ability=${ability}` : ""
        }${topicType !== undefined ? `&topicType=${topicType}` : ""}${
          tag.length > 0 ? `&tag=${tag}` : ""
        }${
          props.paging.pageCount !== 12
            ? `&pageCount=${props.paging.pageCount}`
            : ""
        }&page=1`
      );
    };
    function debounce(func, delay = 250) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(e);
      }, delay);
    }
    debounce(getSearch, 500);
  };

  const handleShowCopy = (data) => {
    const { getTopicById } = props;
    const callback = (value) => {
      setMode("create");
      setCurrentTopicType(value.topic_type);
      let tempTag = [];
      value.tags.map((item) => {
        tempTag.push(item.tag_id);
      });
      let tempAbility = [];
      value.abilities.map((item) => {
        tempAbility.push(item.ability_id);
      });
      if (value.imgs !== null && value.imgs.length > 0) {
        setImageData([
          {
            uid: value.imgs[0].file_id,
            name: "img",
            status: "done",
            url: value.imgs[0].file_url,
          },
        ]);
      }
      if (value.audio !== null && value.audio.length > 0) {
        setAudioData([
          {
            uid: value.audio[0].file_id,
            name: "audio",
            status: "done",
            url: value.audio[0].file_url,
          },
        ]);
      }
      if (value.options !== null) {
        setOptions(value.options);
      }
      if (value.items !== null) {
        setItems(value.items);
        let tempAudio = [];
        let tempImage = [];
        value.items.map((item) => {
          tempAudio.push(
            item.audio !== null
              ? [
                  {
                    uid: item.audio[0].file_id,
                    name: "audio",
                    status: "done",
                    url: item.audio[0].file_url,
                  },
                ]
              : []
          );
          tempImage.push(
            item.imgs !== null
              ? [
                  {
                    uid: item.imgs[0].file_id,
                    name: "imgs",
                    status: "done",
                    url: item.imgs[0].file_url,
                  },
                ]
              : []
          );
        });
        setItemAudio(tempAudio);
        setItemImage(tempImage);
      }

      let payload = {
        ...value,
        tags: tempTag,
        abilities: tempAbility,
        topic_type_id: value.topic_type.topic_type_id,
        topic_group_id:
          value.topic_group !== null
            ? value.topic_group.topic_group_id
            : undefined,
      };
      setCurrentData(payload);
      setFormValue(payload);
      setTimeout(() => {
        setDrawerVisible(true);
      }, 100);
    };
    getTopicById(data.topic_id, setIsLoading, callback);
  };

  const handleShowView = (data) => {
    const { getTopicById } = props;
    const callback = (value) => {
      setMode("view");
      setCurrentData(value);
      setDrawerVisible(true);
    };
    getTopicById(data.topic_id, setIsLoading, callback);
  };

  const renderDrawerTitle = () => {
    if (mode === "create") {
      return props.i18n.topic_manager_create;
    } else if (mode === "update") {
      return props.i18n.topic_manager_update;
    } else if (mode === "view") {
      return props.i18n.topic_manager_view;
    }
  };

  const handleTagChange = (e) => {
    setTag(e);
    navigate(
      `/topic/manager?search=${search}${e !== "" ? `&tag=${e}` : ""}${
        ability.length > 0 ? `&ability=${ability}` : ""
      }${topicType !== undefined ? `&topicType=${topicType}` : ""}${
        props.paging.pageCount !== 12
          ? `&pageCount=${props.paging.pageCount}`
          : ""
      }&page=1`
    );
  };

  const handleAbilityChange = (e) => {
    setAbility(e);
    navigate(
      `/topic/manager?search=${search}${e !== "" ? `&ability=${e}` : ""}${
        tag.length > 0 ? `&tag=${tag}` : ""
      }${topicType !== undefined ? `&topicType=${topicType}` : ""}${
        props.paging.pageCount !== 12
          ? `&pageCount=${props.paging.pageCount}`
          : ""
      }&page=1`
    );
  };

  const handleTopicTypeChange = (e) => {
    setTopicType(e);
    navigate(
      `/topic/manager?search=${search}${
        e !== undefined ? `&topicType=${e}` : ""
      }${ability.length > 0 ? `&ability=${ability}` : ""}${
        tag.length > 0 ? `&tag=${tag}` : ""
      }${
        props.paging.pageCount !== 12
          ? `&pageCount=${props.paging.pageCount}`
          : ""
      }&page=1`
    );
  };

  const handleCloseDrawer = () => {
    // 關閉彈窗
    setDrawerVisible(false);
    setAudioData([]);
    setImageData([]);
    setItems([]);
    setOptions([]);
    setCurrentData({});
    setFormValue({});
  };

  return (
    <div id="character">
      <Row gutter={24}>
        <Col span={16} style={{ display: "flex", alignItems: "center" }}>
          <Search searchValue={search} handleSearch={handleSearchChange} />
          <FormSelect
            allowClear={true}
            showSearch
            style={{ marginLeft: "24px", width: "254px" }}
            mode="multiple"
            placeholder={`${props.i18n.filter}${props.i18n.tag}`}
            options={props.formTagList}
            value={tag}
            onChange={(e) => handleTagChange(e)}
          />
          <FormSelect
            allowClear={true}
            showSearch
            style={{ marginLeft: "24px", width: "254px" }}
            placeholder={`${props.i18n.filter}${props.i18n.ability}`}
            mode="multiple"
            options={abilityList}
            value={ability}
            onChange={(e) => handleAbilityChange(e)}
          />
          <FormSelect
            allowClear={true}
            showSearch
            style={{ marginLeft: "24px", width: "254px" }}
            placeholder={`${props.i18n.filter}${props.i18n.topic_manager_topic_type}`}
            options={props.topicTypeFormList}
            value={topicType}
            onChange={(e) => handleTopicTypeChange(e)}
          />
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              setDrawerVisible(true);
              setMode("import");
              setFormValue({});
              setCurrentData({});
              setHtmlText("");
            }}
            style={{
              width: "128px",
              height: "50px",
              textAlign: "center",
              color: "white",
              backgroundColor: "#4A9E8F",
              border: `2px solid ${props.color.createBtnBorder}`,
              borderRadius: "15px",
              fontSize: "18px",
              marginRight: " 16px",
            }}
          >
            {props.i18n.import_btn}
          </Button>
          <Button
            onClick={() => {
              setDrawerVisible(true);
              setMode("create");
              setFormValue({});
              setCurrentData({});
              setHtmlText("");
            }}
            style={{
              width: "128px",
              height: "50px",
              textAlign: "center",
              color: "white",
              backgroundColor: "#4A9E8F",
              border: `2px solid ${props.color.createBtnBorder}`,
              borderRadius: "15px",
              fontSize: "18px",
            }}
          >
            {props.i18n.create_btn}
          </Button>
        </Col>
      </Row>
      <Row
        gutter={24}
        className="scrollList"
        style={{
          maxHeight: `${props.screenHeight - 273}px`,
          marginRight: "0px !important",
        }}
      >
        {props.topicList.map((item) => {
          return (
            <Col
              span={8}
              style={{
                // height: '225px',
                width: "100%",
                marginTop: "30px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  borderRadius: "30px",
                  boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
                  backdropFilter: "blur(30px) brightness(1.5)",
                }}
              >
                <div style={{ display: "flex", padding: "24px 24px 0px 24px" }}>
                  <div style={{ width: "100%", display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          title={item.topic_id}
                          style={{
                            fontSize: "24px",
                            color:
                              props.color.currentTheme === "dark"
                                ? "white"
                                : "#242933",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            fontWeight: "bold",
                          }}
                        >
                          NO.{item.topic_id}
                        </div>
                        <div
                          style={{
                            padding: "0px 12px",
                            height: "35px",
                            lineHeight: "35px",
                            color: "white",
                            backgroundColor: "#0086BB",
                            borderRadius: "40px",
                          }}
                        >
                          {item.topic_type_name}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "12px",
                          marginBottom: "8px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "18px",
                            color:
                              props.color.currentTheme === "dark"
                                ? "white"
                                : "#242933",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <img
                            alt="question"
                            src={Images.question}
                            style={{
                              width: "14.5px",
                              height: "14.5px",
                              marginRight: "8px",
                            }}
                          />
                          <div
                            style={{
                              height: "35px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: item.topic_title,
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {item.have_img &&
                            item.imgs !== null &&
                            item.imgs.length > 0 && (
                              <img
                                onClick={
                                  item.imgs !== null && item.imgs.length > 0
                                    ? () => window.open(item.imgs[0].file_url)
                                    : () => {}
                                }
                                alt="img"
                                src={Images.img}
                                style={{
                                  width: "24.5px",
                                  height: "23px",
                                  marginRight: "8px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          {item.have_audio &&
                            item.audio !== null &&
                            item.audio.length > 0 && (
                              <img
                                onClick={
                                  item.audio !== null && item.audio.length > 0
                                    ? () => window.open(item.audio[0].file_url)
                                    : () => {}
                                }
                                alt="img"
                                src={Images.sound}
                                style={{
                                  width: "25px",
                                  height: "23px",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#85D1C2",
                    margin: "4px 0px",
                  }}
                ></div>
                <div
                  style={{
                    height: "64px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 24px",
                    backgroundColor: "#ACF8EF",
                    alignItems: "center",
                    borderBottomLeftRadius: "30px",
                    borderBottomRightRadius: "30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    {item.tags.map((child, index) => {
                      return (
                        index < 2 && (
                          <div
                            style={{
                              fontSize: "16px",
                              padding: "0px 12px",
                              backgroundColor: "#FCF1DF",
                              marginRight: "12px",
                              color: "#242933",
                              height: "40px",
                              lineHeight: "40px",
                              textAlign: "center",
                              borderRadius: "15px",
                            }}
                          >
                            {child.tag_name}
                          </div>
                        )
                      );
                    })}
                    {item.tags.length > 2 && (
                      <div
                        style={{
                          width: "36px",
                          height: "40px",
                          lineHeight: "32px",
                          borderRadius: "15px",
                          backgroundColor: "#FCF1DF",
                          color: "#242933",
                          textAlign: "center",
                        }}
                      >
                        ...
                      </div>
                    )}
                  </div>
                  <div style={{ display: "flex" }}>
                    <Button
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#42C1B4",
                        border: `1px solid #C1EFD6`,
                        borderRadius: "15px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => handleShowCopy(item)}
                    >
                      <img
                        alt="copy"
                        src={Images.copy}
                        style={{ width: "22px", height: "14px" }}
                      />
                    </Button>
                    <Button
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#42C1B4",
                        border: `1px solid #C1EFD6`,
                        borderRadius: "15px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => handleShowView(item)}
                    >
                      <img
                        alt="eye"
                        src={Images.eye_open}
                        style={{ width: "22px", height: "14px" }}
                      />
                    </Button>
                    <Button
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#42C1B4",
                        border: `1px solid #C1EFD6`,
                        borderRadius: "15px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => handleShowUpdate(item)}
                    >
                      <img
                        alt="edit"
                        src={Images.edit}
                        style={{ width: "20px", height: "20px" }}
                      />
                    </Button>
                    <Button
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#D53838",
                        border: `1px solid #F31616`,
                        borderRadius: "15px",
                        marginLeft: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => handleDeleteTopic(item.topic_id)}
                    >
                      <img
                        alt="delete"
                        src={Images.delete}
                        style={{ width: "19.4px", height: "19.4px" }}
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>

      <div
        className="pagination"
        style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}
      >
        <Pagination
          defaultCurrent={props.paging.page}
          total={props.paging.total}
          current={props.paging.page}
          pageSize={props.paging.pageCount}
          showSizeChanger={false}
          onChange={handlePageChange}
        />
        <Select
          id="selectList"
          style={{ width: "112px", marginLeft: "16px" }}
          defaultValue={props.paging.pageCount}
          value={props.paging.pageCount}
          onChange={handleSizeChange}
        >
          {sizeList.map((item) => (
            <Select.Option value={item.id} key={item.id}>
              {item.name} / {props.i18n.page}
            </Select.Option>
          ))}
        </Select>
      </div>
      {
        <Drawer
          placement="right"
          className={
            props.color.currentTheme === "dark" ? "dark_member" : "light_member"
          }
          closable={false}
          maskClosable={mode === "view" ? true : false}
          onClose={handleCloseDrawer}
          visible={drawerVisible}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={Images.close}
              alt="close"
              style={{ width: "65px", height: "74px", cursor: "pointer" }}
              onClick={handleCloseDrawer}
            />
            <div
              style={{
                fontSize: "24px",
                color: props.color.inputDrawerTitleColor,
                marginLeft: "30px",
              }}
            >
              {renderDrawerTitle()}
            </div>
          </div>
          {renderDrawerView()}
        </Drawer>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: _.get(state, "Global.theme", "light"),
    themeMode: _.get(state, "Global.themeMode", "dark"),
    color: _.get(state, "Global.color", {}),
    i18n: _.get(state, "Global.i18n", {}),
    i18nMode: _.get(state, "Global.i18nMode", {}),
    screenHeight: _.get(state, "Global.screenHeight", 0),
    topicList: _.get(state, "TopicManager.topicList", []),
    paging: _.get(state, "TopicManager.paging", {}),
    formTagList: _.get(state, "Tag.formTagList", []),
    topicTypeFormList: _.get(state, "TopicManager.topicTypeFormList", []),
    topicTypeList: _.get(state, "TopicManager.topicTypeList", []),
    topicGroupFormList: _.get(state, "TopicGroup.topicGroupFormList", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleCreateTopic(payload, loading, callback, getData) {
      dispatch({
        type: "HANDLE_CREATE_TOPIC",
        payload,
        loading,
        callback,
        getData,
      });
    },
    handleUpdateTopic(payload, loading, callback, getData) {
      dispatch({
        type: "HANDLE_UPDATE_TOPIC",
        payload,
        loading,
        callback,
        getData,
      });
    },
    handleImportTopic(payload, loading, callback, getData) {
      dispatch({
        type: "HANDLE_IMPORT_TOPIC",
        payload,
        loading,
        callback,
        getData,
      });
    },
    handleDeleteTopic(payload, loading, callback, getData) {
      dispatch({
        type: "HANDLE_DELETE_TOPIC",
        payload,
        loading,
        callback,
        getData,
      });
    },
    getTopicList(payload, loading, callback) {
      dispatch({ type: "GET_TOPIC_LIST", payload, loading, callback });
    },
    getTopicById(payload, loading, callback) {
      dispatch({ type: "GET_TOPIC_BY_ID", payload, loading, callback });
    },
    handleCreateTag(payload, loading, callback) {
      dispatch({ type: "HANDLE_CREATE_TAG", payload, loading, callback });
    },
    saveTagList(payload) {
      dispatch({ type: "SAVE_TAG_LIST", payload });
    },
    getTagFormList(payload, loading, callback) {
      dispatch({ type: "GET_TAG_FORM_LIST", payload, loading, callback });
    },
    getTopicTypeFormList(payload, loading, callback) {
      dispatch({
        type: "GET_TOPIC_TYPE_FORM_LIST",
        payload,
        loading,
        callback,
      });
    },
    getTopicTypeList(payload, loading, callback) {
      dispatch({ type: "GET_TOPIC_TYPE_LIST", payload, loading, callback });
    },
    getTopicGroupListByForm(payload, loading, callback) {
      dispatch({
        type: "GET_TOPIC_GROUP_LIST_BY_FORM",
        payload,
        loading,
        callback,
      });
    },
    handleCreateFormTopicGroup(payload, loading, callback) {
      dispatch({
        type: "HANDLE_CREATE_FORM_TOPIC_GROUP",
        payload,
        loading,
        callback,
      });
    },
    saveTopicGroup(payload) {
      dispatch({ type: "SAVE_TOPIC_GROUP", payload });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicManager);
